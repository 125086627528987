import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";

import AuthGabarit from "../../components/AuthGabarit";
import Input from "../../components/Input";
import API from "../../utils/api";

export default function ValidateEmailPage() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await API.post("/firstLogin", data);
      navigate("/login");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <AuthGabarit DisplayLoginButton>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        <p className="text-sm">
          Veuillez saisir dans le champ ci-dessous votre adresse email lié à
          votre compte. Un lien vous sera envoyé pour valider votre compte.
        </p>
        <Input
          id="email"
          label="Email"
          placeholder="Veuillez saisir votre adresse mail"
          type="email"
          {...register("email", {
            required: "L'email est requis",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "L'email n'est pas valide",
            },
          })}
          error={errors.email}
        />

        <button
          type="submit"
          className="px-10 py-1 mx-auto border rounded w-fit border-primary text-primary hover:bg-primary disabled:bg-transparent hover:text-white"
          disabled={loading}
        >
          {loading ? <Loader /> : "Valider"}
        </button>
      </form>
    </AuthGabarit>
  );
}
