import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "rsuite";

import { useAuth } from "context/auth-context";

import Gabarit from "../components/Gabarit";
import Input from "../components/Input";

import { passwordErrorMessage, passwordPattern } from "../utils";
import API from "../utils/api";

export default function AccountPage() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { user } = useAuth();

  const password = watch("password");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await API.put("/users/reset-password", data);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Gabarit label="Mon compte" className="flex flex-col space-y-2">
      <div className="flex flex-col p-5 space-y-2 bg-white shadow-lg rounded-xl">
        <div className="grid gap-4 md:grid-cols-2">
          <Input
            id="lastName"
            label="Nom"
            placeholder="Veuillez saisir votre nom"
            value={user.lastName}
            type="text"
            disabled
          />
          <Input
            id="firstName"
            label="Prénom"
            placeholder="Veuillez saisir votre prénom"
            value={user.firstName}
            type="text"
            disabled
          />
          <Input
            id="email"
            label="email"
            placeholder="Veuillez saisir votre email"
            value={user.email}
            type="text"
            disabled
          />
          <Input
            id="adresse"
            label="Addresse"
            placeholder="Veuillez saisir votre adresse"
            value={
              user.location?.street &&
              user.location?.zipCode &&
              user.location?.city
                ? user.location?.street +
                  ", " +
                  user.location?.zipCode +
                  " " +
                  user.location?.city
                : ""
            }
            type="text"
            disabled
          />
          <Input
            id="phone"
            label="Fixe"
            placeholder="Veuillez saisir votre téléphone"
            value={user.linePhone}
            type="text"
            disabled
          />
          <Input
            id="mobilephone"
            label="Mobile"
            placeholder="Veuillez saisir votre téléphone mobile"
            value={user.mobilePhone}
            type="text"
            disabled
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid gap-4 md:grid-cols-2"
        >
          <Input
            id="password"
            label="Mot de passe"
            placeholder="Veuillez saisir votre mot de passe"
            type="password"
            {...register("password", {
              required: "Le mot de passe est requis",
              pattern: {
                value: passwordPattern,
                message: passwordErrorMessage,
              },
            })}
            error={errors.password}
          />

          <Input
            id="confirmPassword"
            label="Confirmer mot de passe"
            placeholder="Veuillez saisir votre mot de passe"
            type="password"
            hidebutton="true"
            {...register("confirmPassword", {
              required: "Ce champ est requis",
              validate: (value) =>
                value === password || "Les mots de passe ne correspondent pas",
            })}
            error={errors.confirmPassword}
          />

          <div className="mx-auto md:col-span-2">
            <button
              type="submit"
              className="px-10 py-1 border rounded w-fit border-primary text-primary hover:bg-primary disabled:bg-transparent hover:text-white"
              disabled={loading}
            >
              {loading ? <Loader /> : "Valider"}
            </button>
          </div>
        </form>
      </div>
    </Gabarit>
  );
}
