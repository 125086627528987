import { useEffect, useState } from "react";

import { filtersToQueryString } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import TableMonitoring from "../components/Monitoring/Table";

export default function MonitoringPage() {
  const [monitorings, setMonitorings] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [monitoringLimit, setMonitoringLimit] = useState(25);
  const [monitoringSort, setMonitoringSort] = useState(
    `{"startDate":-1, "startDate":-1}`
  );
  const [monitoringPage, setMonitoringPage] = useState(1);
  const [monitoringFilters, setMonitoringFilters] = useState({});

  useEffect(() => {
    async function defaultValue() {
      const filters = {
        and: [{ patients: { $exists: true } }],
      };
      setMonitoringFilters(filters);
      await getMonitorings({
        limit: monitoringLimit,
        sort: monitoringSort,
        page: monitoringPage,
        filters,
      });
    }
    defaultValue();
  }, [monitoringLimit, monitoringSort, monitoringPage]);

  const getMonitorings = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });

      const response = await API.get(
        `/monitorings?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setMonitorings(response.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleSort = async (sort) => {
    setMonitoringSort(sort);
    setMonitoringPage(1);
    await getMonitorings({
      sort,
      limit: monitoringLimit,
      page: 1,
      filters: monitoringFilters,
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setMonitoringLimit(limit);
    setMonitoringPage(1);
    await getMonitorings({
      sort: monitoringSort,
      limit,
      page: 1,
      filters: monitoringFilters,
    });
    return limit;
  };

  const handlePage = async (page) => {
    setMonitoringPage(page);
    await getMonitorings({
      sort: monitoringSort,
      limit: monitoringLimit,
      page,
      filters: monitoringFilters,
    });
    return page;
  };

  return (
    <Gabarit label="Monitoring" className="flex flex-col gap-3 md:flex-row">
      <TableMonitoring
        monitorings={monitorings}
        isLoading={isLoading}
        limit={monitoringLimit}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
      />
    </Gabarit>
  );
}
