import { useState } from "react";
import { FaUserAlt, FaUserMd } from "react-icons/fa";
import { Loader, Tag, TagPicker } from "rsuite";

const UserTagPicker = ({
  label,
  onSearch,
  onChanged,
  onClose,
  className,
  placeholder,
  value,
  disabled,
}) => {
  const [cacheData, setCacheData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const handleSelect = async (values = [], item, event) => {
    let selectedList = cacheData.filter((data) => values.includes(data.value));
    if (!selectedList.includes(item.value)) selectedList.push(item);
    setCacheData(selectedList);
  };

  const handleSearch = async (word) => {
    if (!word) {
      return;
    }
    setLoading(true);
    const result = await onSearch(word);
    result.items = result.items ? result.items : [];
    setLoading(result.loading);
    setItems(result.items);
  };

  const handleChange = async (result = []) => {
    await onChanged(result);
  };

  const getlabelGroup = (label) => {
    switch (label) {
      case "prescriber":
        return "Prescripteurs";
      case "patient":
        return "Patients";
      case "admin":
        return "Administrateurs";
      case "commercial":
        return "Commerciales";
      case "director":
        return "Directeurs";
      default:
        return "Commerciales";
    }
  };

  return (
    <TagPicker
      data={items}
      cacheData={cacheData}
      groupBy="role"
      disabled={disabled}
      cleanable={false}
      value={value}
      placeholder={placeholder}
      size="sm"
      className={className}
      block
      onChange={handleChange}
      onSearch={handleSearch}
      onSelect={handleSelect}
      renderMenu={(menu) => {
        if (loading) {
          return (
            <div className="p-4 text-center text-neutral-dark">
              <Loader /> Chargement...
            </div>
          );
        }
        return menu;
      }}
      renderMenuItem={(label, item) => {
        return (
          <span className="flex flex-row items-center gap-1">
            {item.role === "prescriber" ? (
              <FaUserMd className="text-primary" />
            ) : (
              <FaUserAlt className="text-primary" />
            )}
            {label}
          </span>
        );
      }}
      renderMenuGroup={(label, item) => {
        return (
          <span className="flex flex-row items-center gap-1">
            <FaUserMd className="text-primary" /> {getlabelGroup(label)} - (
            {item.children.length})
          </span>
        );
      }}
      renderValue={(values, items) => {
        return items.map((item, index) => (
          <Tag
            key={index}
            closable
            className={disabled ? "pointer-events-none" : ""}
            onClose={async () => onClose(item.value)}
          >
            <span className="flex flex-row items-center gap-1">
              <FaUserMd className="text-primary" /> {item.label}
            </span>
          </Tag>
        ));
      }}
    />
  );
};

export default UserTagPicker;
