import { forwardRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Input = forwardRef((props, ref) => {
  const { label, error, type, className, hidebutton } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(type || "text");

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div className="flex flex-col space-y-1">
      {label && (
        <label className="text-xs font-semibold text-neutral-dark">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          {...props}
          ref={ref}
          type={inputType}
          className={`w-full h-full form-input block px-3 py-2 bg-white border rounded-md text-sm shadow-sm placeholder-gray-400
          focus:outline-none focus:ring-1 disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 text-neutral-dark ${
            className || ""
          } ${
            error
              ? "border-red-500 focus:ring-red-300"
              : "border-gray-300 focus:ring-primary focus:border-primary"
          } border`}
        />
        {type === "password" && !hidebutton && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 flex items-center px-3 text-sm leading-5 hover:bg-gray-300 rounded-e-md"
          >
            <div className="flex items-center">
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </div>
          </button>
        )}
      </div>
      {error && <div className="text-xs text-red-500">{error.message}</div>}
    </div>
  );
});

export default Input;
