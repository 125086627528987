import { forwardRef } from "react";
import { Link } from "react-router-dom";

const MobileNavList = forwardRef(
  ({ routes, isActive, className, handleLogout }, ref) => {
    return (
      <div ref={ref} className={`relative ${className}`}>
        <div className="absolute right-0 inset-y-9">
          <ul className="flex flex-col items-center font-semibold uppercase">
            {routes.map((route, index) => (
              <div key={index} className="w-full">
                {route.items ? (
                  <div key={`items-${index}`}>
                    {route.items.map((item, indexItem) => (
                      <li
                        key={indexItem}
                        className={`flex flex-col h-fit w-full text-center hover:scale-105  ${
                          isActive(item.path)
                            ? "bg-light"
                            : "bg-primary hover:bg-secondary"
                        }`}
                      >
                        <Link
                          key={`item-${indexItem}`}
                          to={item.path}
                          className="p-2 !text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </div>
                ) : (
                  <li
                    key={index}
                    className={`flex flex-col h-fit w-full text-center hover:scale-105 ${
                      isActive(route.path)
                        ? "bg-light"
                        : "bg-primary hover:bg-secondary"
                    }`}
                  >
                    <Link to={route.path} className="p-2 !text-white">
                      {route.name}
                    </Link>
                  </li>
                )}
              </div>
            ))}
            <li
              className={`flex flex-col h-fit w-full text-center hover:scale-105 ${
                isActive("/mon-compte")
                  ? "bg-light"
                  : "bg-primary hover:bg-secondary"
              } mt-1`}
            >
              <Link to={"/mon-compte"} className="p-2 !text-white">
                mon compte
              </Link>
            </li>
            <li
              className="w-full p-2 bg-red-500 cursor-pointer hover:scale-105"
              onClick={handleLogout}
            >
              <div className="text-center text-white">déconnexion</div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
);

export default MobileNavList;
