import _ from "lodash";
import moment from "moment";
import { GiSettingsKnobs } from "react-icons/gi";
import {
  CheckPicker,
  Checkbox,
  DatePicker,
  DateRangePicker,
  InputNumber,
  SelectPicker,
} from "rsuite";

import { checkNumber } from "../../../utils";
import { Locale, diabeteTypes, tirList } from "./constants";

import ButtonIcon from "../../ButtonIcon";
import TutoSpeechBubble from "../../TutoSpeechBubble";
import UserTagPicker from "../../User/UserTagPicker";

export default function TablePatientFilters({
  className,
  isLoading,
  tutoMode,
  onRemovePrescriber,
  onSearchPrescribers,
  onChangePrescriber,
  searchByPrescriber,
  filters,
  handleFilters,
  handleRemoveFilter,
  handleRemoveAllFilters,
  prescriberItems,
  pumps,
  searchByRenewalStatus,
  firstLogin,
}) {
  const { afterToday } = DateRangePicker;

  return (
    <div
      id="filter"
      className={`bg-white rounded-lg shadow-xl h-fit lg:max-w-[205px] ${
        isLoading ? "pointer-events-none" : ""
      } ${className}`}
    >
      <div className="flex flex-row items-center gap-2 px-3 py-2 font-semibold text-white rounded-t-lg bg-primary">
        <GiSettingsKnobs size={12} className="rotate-90" />
        Filtrer
      </div>
      <div
        className={`flex flex-col p-3 gap-2 ${
          tutoMode ? "pointer-events-none" : ""
        }`}
      >
        {searchByPrescriber ? (
          <>
            <label className="text-xs font-semibold text-neutral-dark">
              Prescripteurs
            </label>
            <UserTagPicker
              placeholder="Saisir un prescripteur"
              value={prescriberItems}
              disabled={isLoading}
              onClose={async (removedItem) =>
                await onRemovePrescriber(removedItem)
              }
              onSearch={async (word) => await onSearchPrescribers(word)}
              onChanged={async (value = []) => await onChangePrescriber(value)}
            />
          </>
        ) : null}
        <div>
          <label className="text-xs font-semibold text-neutral-dark">
            Type de diabète
          </label>
          <CheckPicker
            data={diabeteTypes}
            placeholder="Choisir un type"
            cleanable={false}
            size="sm"
            block
            value={
              filters?.and?.find(
                (filter) => filter["diabeteType.id"] !== undefined
              )?.["diabeteType.id"].$in ?? []
            }
            searchable={false}
            onChange={async (value) =>
              await handleFilters("and", "diabeteType.id", "$in", value)
            }
          />
        </div>

        <div>
          <label className="mb-1 text-xs font-semibold text-neutral-dark">
            Date prestation:
          </label>
          <div className="mb-2">
            <span className="text-xs text-primary">Installé depuis le :</span>
            <DatePicker
              locale={Locale}
              format="dd/MM/yyyy"
              placeholder="Choisir une date"
              size="sm"
              shouldDisableDate={afterToday()}
              block
              value={
                filters?.and?.find(
                  (filter) =>
                    filter["firstContract.startDate"] !== undefined &&
                    filter["firstContract.startDate"].$gte !== undefined
                )?.["firstContract.startDate"].$gte ?? null
              }
              onChange={async (value) =>
                value
                  ? await handleFilters(
                      "and",
                      "firstContract.startDate",
                      "$gte",
                      value
                    )
                  : await handleRemoveFilter(
                      "and",
                      "firstContract.startDate",
                      "$gte"
                    )
              }
            />
          </div>
          <div>
            <span className="text-xs text-primary">Installé avant le :</span>
            <DatePicker
              locale={Locale}
              format="dd/MM/yyyy"
              placeholder="Choisir une date"
              size="sm"
              defaultValue={null}
              shouldDisableDate={afterToday()}
              block
              value={
                filters?.and?.find(
                  (filter) =>
                    filter["firstContract.startDate"] !== undefined &&
                    filter["firstContract.startDate"].$lte !== undefined
                )?.["firstContract.startDate"].$lte ?? null
              }
              onChange={async (value) =>
                value
                  ? await handleFilters(
                      "and",
                      "firstContract.startDate",
                      "$lte",
                      value
                    )
                  : await handleRemoveFilter(
                      "and",
                      "firstContract.startDate",
                      "$lte"
                    )
              }
            />
          </div>
        </div>

        <hr className="my-2" />

        <div className="flex flex-col gap-1">
          <span className="text-xs font-semibold text-neutral-dark">
            Type de pompe
          </span>
          <SelectPicker
            data={_.map(pumps, (obj) =>
              _.mapKeys(obj, (value, key) => {
                if (key === "model") {
                  return "label";
                } else if (key === "_id") {
                  return "value";
                }
                return key;
              })
            )}
            placeholder="Choisir une pompe"
            size="sm"
            defaultValue={null}
            placement="bottomStart"
            block
            searchable={false}
            value={
              filters?.and?.find(
                (filter) =>
                  filter["pump._id"] !== undefined &&
                  filter["pump._id"].$eq !== undefined
              )?.["pump._id"].$eq ?? null
            }
            onChange={async (value) => {
              value
                ? await handleFilters(
                    "and",
                    "pump._id",
                    "$eq",
                    value ? Number(value) : null
                  )
                : await handleRemoveFilter("and", "pump._id", "$eq");
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-xs font-semibold text-neutral-dark">HbA1c</span>
          <InputNumber
            size="sm"
            prefix="<"
            postfix="%"
            min={0}
            value={
              filters?.and?.find(
                (filter) =>
                  filter["lastIntervention.metrics.HbA1c"] !== undefined &&
                  filter["lastIntervention.metrics.HbA1c"].$lt !== undefined
              )?.["lastIntervention.metrics.HbA1c"].$lt ?? null
            }
            onChange={async (value) => {
              value && checkNumber(value)
                ? await handleFilters(
                    "and",
                    "lastIntervention.metrics.HbA1c",
                    "$lt",
                    Number(value)
                  )
                : await handleRemoveFilter(
                    "and",
                    "lastIntervention.metrics.HbA1c",
                    "$lt"
                  );
            }}
          />
          <InputNumber
            size="sm"
            prefix=">"
            className="mt-1"
            postfix="%"
            min={0}
            step={1}
            value={
              filters?.and?.find(
                (filter) =>
                  filter["lastIntervention.metrics.HbA1c"] !== undefined &&
                  filter["lastIntervention.metrics.HbA1c"].$gt !== undefined
              )?.["lastIntervention.metrics.HbA1c"].$gt ?? null
            }
            onChange={async (value) => {
              value && checkNumber(value)
                ? await handleFilters(
                    "and",
                    "lastIntervention.metrics.HbA1c",
                    "$gt",
                    Number(value)
                  )
                : await handleRemoveFilter(
                    "and",
                    "lastIntervention.metrics.HbA1c",
                    "$gt"
                  );
            }}
          />
        </div>

        <div>
          <label className="text-xs font-semibold text-neutral-dark">TIR</label>
          <SelectPicker
            data={tirList}
            size="sm"
            block
            searchable={false}
            value={
              filters?.and?.find(
                (filter) =>
                  filter["lastIntervention.metrics.tir"] !== undefined &&
                  filter["lastIntervention.metrics.tir"].$lt !== undefined
              )?.["lastIntervention.metrics.tir"].$lt ?? null
            }
            onChange={async (value) =>
              value
                ? await handleFilters(
                    "and",
                    "lastIntervention.metrics.tir",
                    "$lt",
                    value
                  )
                : await handleRemoveFilter(
                    "and",
                    "lastIntervention.metrics.tir",
                    "$lt"
                  )
            }
          />
        </div>

        {firstLogin ? (
          <div>
            <Checkbox
              checked={
                filters?.and?.find(
                  (filter) =>
                    filter["lastIntervention.documents"] !== undefined &&
                    filter["lastIntervention.documents"].$elemMatch !==
                      undefined
                )
                  ? true
                  : false
              }
              onChange={async (value, checked) => {
                checked
                  ? await handleFilters(
                      "and",
                      "lastIntervention.documents",
                      "$elemMatch",
                      {
                        date: {
                          $gte: {
                            $date: `${moment(firstLogin).format(
                              "YYYY-MM-DD[T00:00:00.000Z]"
                            )}`,
                          },
                        },
                        importType: "Courrier",
                        firstReader: { $exists: false },
                      }
                    )
                  : await handleRemoveFilter(
                      "and",
                      "lastIntervention.documents",
                      "$elemMatch"
                    );
              }}
            >
              <div className="text-xs font-bold text-primary">CR non lu</div>
              <div className="text-xs tewt-secondary">
                {`Première connexion le ${moment(firstLogin).format(
                  "DD/MM/YYYY à HH:mm"
                )}`}
              </div>
            </Checkbox>
          </div>
        ) : null}
        {searchByRenewalStatus ? (
          <div>
            <Checkbox
              checked={
                filters?.and?.find(
                  (filter) =>
                    filter["renewal.hasRenewal"] !== undefined &&
                    filter["renewal.hasRenewal"].$eq !== undefined
                )?.["renewal.hasRenewal"].$eq ?? false
              }
              onChange={async (value, checked) =>
                checked === true
                  ? await handleFilters(
                      "and",
                      "renewal.hasRenewal",
                      "$eq",
                      true
                    )
                  : await handleRemoveFilter("and", "renewal.hasRenewal", "$eq")
              }
            >
              <div className="text-xs font-bold text-primary">
                Prescription à renouveler
              </div>
              <TutoSpeechBubble
                text="Cette option permet de filtrer les patients en attente de renouvellement."
                isVisible={tutoMode}
                pointType="default"
                className="lg:block top-[130%] left-[25px] sm:top-[100%] sm:left-[15%]"
                pointPlacement="top-left"
              />
            </Checkbox>
          </div>
        ) : null}
        <ButtonIcon
          className="w-full p-1 text-white bg-primary hover:bg-light"
          text="Réinitialiser"
          textClassName=""
          style={{ borderRadius: 5 }}
          onClick={async () => await handleRemoveAllFilters()}
        />
      </div>
    </div>
  );
}
