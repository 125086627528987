import { Toggle } from "rsuite";

import NavBar from "./Navbar";

export default function Gabarit({
  label,
  children,
  className,
  tutoMode,
  setTutoMode,
  leftComponent,
}) {
  return (
    <>
      <NavBar />
      {label ? (
        <div className="flex items-center justify-between gap-1 px-3 pt-3">
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-primary">{label}</p>
            {setTutoMode ? (
              <Toggle
                checked={tutoMode}
                onChange={(checked) => setTutoMode(checked)}
                checkedChildren="Mode démo"
                unCheckedChildren="Mode démo"
              />
            ) : null}
          </div>

          {leftComponent && <div className="self-end">{leftComponent}</div>}
        </div>
      ) : null}
      <section className={`p-3 ${className}`}>{children}</section>
    </>
  );
}
