//import { useState } from "react";
//import { AiOutlineClose } from "react-icons/ai";

export default function Banner(/*setAcceptedCookies*/) {
  //const [close, setClose] = useState(false);
  //if (!close) {
  if (true) {
    return (
      <>
        <div className="fixed inset-x-0 bottom-0">
          <div className="bg-primary">
            <div className="max-w-7xl ml-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <p className="ml-3 text-xs md:text-sm font-medium text-white">
                    <span>
                      En poursuivant votre navigation sur ce site, vous acceptez
                      l'utilisation de cookies.{" "}
                      <a
                        href="politique-cookies"
                        target="_blank"
                        className="text-secondary underline hover:opacity-70 hover:text-secondary"
                      >
                        En savoir plus
                      </a>
                    </span>
                  </p>
                </div>
                {/*
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    typebutton
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-light hover:opacity-70"
                    onClick={() => {
                      setAcceptedCookies(true);setClose(true);
                    }}
                  >
                    Accepter
                  </button>
                </div>
                */}
                {/*<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3 ml-auto">
                  <button
                    type="button"
                    className="-mr-1 flex p-2 rounded-md text-white hover:text-secondary focus:outline-none focus:ring-2 sm:-mr-2"
                  >
                    <AiOutlineClose
                      className="h-6 w-6"
                      aria-hidden="true"
                      onClick={() => setClose(true)}
                    />
                  </button>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
