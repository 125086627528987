export const ButtonIcon = ({
  text,
  className,
  icon,
  IconSize,
  iconClassName,
  onClick,
  style,
  iconStyle,
  textClassName,
  tooltipId,
}) => (
  <button
    className={className}
    onClick={onClick}
    style={style}
    data-tip={tooltipId}
    data-for={tooltipId ? tooltipId : null}
  >
    {icon ? icon : null}
    <span className={textClassName ? textClassName : "text-sm"}>{text}</span>
  </button>
);

export default ButtonIcon;
