import { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { GoChevronDown } from "react-icons/go";
import { Loader } from "rsuite";

import { useModal } from "../../../context/modal-context";
import ModalUpdatePrescriber from "../Modal/update";
import columns from "./columns";
import conditionalRowStyles from "./conditionalRowStyles";
import customStyles from "./customStyles";

export default function TablePrescriber({
  className,
  tutoMode,
  prescribers,
  isLoading,
  limit,
  handleUpdatePrescriber,
  handleLimit,
  handleSort,
  handlePage,
  handleSync,
  isLoadingSync,
  isMenuOpen,
}) {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const { showModal, setModalLabel } = useModal();

  const onClickButtonPrescriberUpdate = async (prescriber) => {
    setModalLabel("Modifier un prescripteur");
    showModal(
      <ModalUpdatePrescriber
        prescriber={prescriber}
        isLoading={isUpdateLoading}
        setIsLoading={setIsUpdateLoading}
        onClick={handleUpdatePrescriber}
      />
    );
  };

  createTheme("solarized", {
    text: {
      primary: "#808485",
      secondary: "#808485",
    },
    background: {
      default: "white",
    },
    divider: {
      default: "transparent",
    },
    action: {
      button: "white",
      hover: "white",
      disabled: "white",
    },
    highlightOnHover: {
      default: "#F2F3F5",
      text: "##808485",
    },
    striped: {
      default: "#F6F9FF",
      text: "#808485",
    },
  });

  return (
    <div className={`w-full h-full rounded-lg ${className}`}>
      <DataTable
        columns={columns(
          onClickButtonPrescriberUpdate,
          handleSync,
          isLoadingSync
        )}
        className={`rounded-b-xl ${tutoMode ? "!pointer-events-none" : ""}`}
        data={prescribers.docs}
        noDataComponent={
          <span className="w-full font-semibold text-center">
            Pas de données
          </span>
        }
        customStyles={customStyles(tutoMode, isMenuOpen)}
        conditionalRowStyles={conditionalRowStyles}
        defaultSortFieldId={2}
        defaultSortAsc={true}
        sortIcon={<GoChevronDown className="ml-1" color="var(--light)" />}
        striped
        highlightOnHover
        pagination
        theme="solarized"
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[25, 50, 100]}
        progressPending={isLoading}
        progressComponent={<Loader className="w-full text-center" />}
        paginationServer
        paginationDefaultPage={prescribers.page}
        paginationServerOptions={{
          persistSelectedOnPageChange: true,
          persistSelectedOnSort: true,
        }}
        paginationTotalRows={prescribers.totalDocs}
        onChangePage={async (page) => await handlePage(page)}
        onChangeRowsPerPage={async (newPerPage) =>
          await handleLimit(newPerPage)
        }
        sortServer
        onSort={async (column, sortDirection) =>
          await handleSort(
            `{"${column.sortfield}":${sortDirection === "desc" ? -1 : 1}${
              column.sortfield === "lastName"
                ? `,"firstName":${sortDirection === "desc" ? -1 : 1}`
                : ""
            }}`
          )
        }
        dense
        paginationComponentOptions={{
          rowsPerPageText: "Utilisateur par page:",
          rangeSeparatorText: "sur",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
      />
    </div>
  );
}
