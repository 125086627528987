import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";

import { useAuth } from "context/auth-context";

import AuthGabarit from "../../components/AuthGabarit";
import Input from "../../components/Input";
import CookieBanner from "../../components/CookieBanner";

import { passwordErrorMessage, passwordPattern } from "../../utils";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await login(data.email, data.password);
      navigate("/");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <AuthGabarit>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        <Input
          id="email"
          label="Email"
          placeholder="Veuillez saisir votre adresse mail"
          type="email"
          {...register("email", {
            required: "L'email est requis",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "L'email n'est pas valide",
            },
          })}
          error={errors.email}
        />

        <Input
          id="password"
          label="Mot de passe"
          placeholder="Veuillez saisir votre mot de passe"
          type="password"
          {...register("password", {
            required: "Le mot de passe est requis",
            pattern: {
              value: passwordPattern,
              message: passwordErrorMessage,
            },
          })}
          error={errors.password}
        />

        <a
          href="/forgot-password"
          className="text-xs text-blue-800 underline w-fit"
        >
          <span className="underline">Mot de passe oublié</span>
        </a>

        <button
          type="submit"
          className="px-10 py-1 mx-auto border rounded w-fit border-primary text-primary hover:bg-primary disabled:bg-transparent hover:text-white"
          disabled={loading}
        >
          {loading ? <Loader /> : "Connexion"}
        </button>

        <span>
          C'est votre première connexion ? Renseignez-votre email et{" "}
          <a
            href="validate-email"
            className="text-blue-800 cursor-pointer hover:opacity-70"
          >
            Cliquez ici pour valider votre compte.
          </a>
        </span>
      </form>
      <CookieBanner />
    </AuthGabarit>
  );
}
