// Navbar/DropdownMenu.js
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NavContext } from "./index";

const DropdownMenu = ({ route }) => {
  const { isMenuOpen, setIsMenuOpen, isActive } = useContext(NavContext);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsMenuOpen]);

  const toggleDropdown = () => setIsMenuOpen(!isMenuOpen);

  return (
    <li ref={dropdownRef} className="relative">
      <button
        onClick={toggleDropdown}
        className="font-semibold uppercase lg:hover:scale-105 text-primary"
      >
        {route.name}
      </button>
      {isMenuOpen && (
        <div className="absolute inset-y-9">
          <ul className="flex flex-col items-center">
            {route.items.map((item, index) => (
              <li
                key={index}
                className={`lg:hover:scale-105 w-full cursor-pointer font-semibold uppercase p-2 hover:bg-secondary ${
                  isActive(`${item.path}`) ? "bg-light" : "bg-primary"
                }`}
                onClick={() => navigate(item.path)}
              >
                <div className="text-center text-white">{item.name}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default DropdownMenu;
