import moment from "moment";
import _ from "lodash";
import { Tooltip } from "react-tooltip";
import {
  FaFileSignature,
  FaCheck,
  FaFileExport,
  FaTimes,
} from "react-icons/fa";

import ButtonIcon from "../../ButtonIcon";

const columns = (handleDownloadOrdo, handleManualUpload, manualUploadItems) => [
  {
    name: "ID pres.",
    selector: (row) => `${row._id}`,
    sortable: false,
    sortfield: "_id",
    width: "100px",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="font-medium">
        {row._id}
      </span>
    ),
  },
  {
    name: "Nom du patient",
    selector: (row) => `${row.patient.fullName}`,
    sortable: true,
    sortfield: "patient.lastName",
    cell: (row) => (
      <a
        href={`patients/${row.patientId}`}
        target="_blank"
        rel="noreferrer"
        className="text-primary font-semibold hover:text-light focus:text-light"
      >
        <span>{`${row.patient.lastName} ${row.patient.firstName}`}</span>
      </a>
    ),
  },
  {
    name: "Date de signature",
    selector: (row) => `${row.signatureDate}`,
    sortable: true,
    sortfield: "signatureDate",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="font-medium text-dark">
        {moment(row.signatureDate).format("DD/MM/YYYY HH:mm")}
      </span>
    ),
  },
  {
    name: "Prescripteur",
    selector: (row) => `${row.prescriber.fullName}`,
    sortable: true,
    sortfield: "prescriber.fullName",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="font-medium">
        {row.prescriber.fullName}
      </span>
    ),
  },
  {
    name: "Date début pres.",
    selector: (row) => `${row.startDate}`,
    sortable: false,
    sortfield: "startDate",
    width: "132px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.startDate).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    name: "Date fin pres.",
    selector: (row) => `${row.endDate}`,
    sortable: false,
    sortfield: "endDate",
    width: "110px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.endDate).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    name: "Retour API SANO",
    selector: (row) => `${row.sano?.log?.status}`,
    sortable: false,
    sortfield: "row.sano.log.status",
    /*cell: (row) => (
      <span data-tag="allowRowEvents">
        {row.sano?.status === "error"
          ? `${row.sano?.log?.statusText || ""}`
          : ""}
      </span>
    ),*/
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {row.sano?.log?.status
          ? `${row.sano?.log?.status} - ${row.sano?.log?.statusText}`
          : ""}
      </span>
    ),
  },
  {
    name: "Document signé",
    selector: (row) => `${row.signedFile.id}`,
    sortable: false,
    sortfield: null,
    cell: (row) => {
      const doc = row.signedFile;
      return (
        <div
          key={`Sdoc_${doc.id}`}
          data-tip={doc.name}
          className="flex mx-auto justify-center bg-dark rounded cursor-pointer hover:bg-secondary p-1"
          onClick={async () => {
            await handleDownloadOrdo(row._id);
          }}
        >
          <FaFileSignature
            size={20}
            color="white"
            className="ml-1"
            data-tooltip-id={`doc_${doc.id}`}
            data-tooltip-content={doc.name}
            data-tooltip-place="left"
          />
          <Tooltip id={`doc_${doc.id}`} />
        </div>
      );
    },
  },
  {
    name: "État",
    selector: (row) => `${row.sano?.status}`,
    sortable: true,
    sortfield: "sano.status",
    width: "80px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {StatusButton(
          _.some(manualUploadItems, { id: row._id })
            ? "manual"
            : row.sano?.status
        )}
      </span>
    ),
  },
  {
    name: "Action",
    width: "175px",
    sortable: false,
    cell: (row) => {
      const manualUploadItem = _.find(manualUploadItems, { id: row._id });
      const isManual =
        row.sano?.status === "manual" ||
        row.sano?.status === "manual-transmitted";
      if (isManual || manualUploadItem) {
        return (
          <div className="flex flex-col text-neutral-500 p-1 text-xs text-center">
            <span>
              Transferé par{" "}
              <span className="font-semibold">
                {isManual && row.sano?.manualLog?.user
                  ? row.sano.manualLog.user
                  : manualUploadItem.user}
              </span>
            </span>
            <label>{`le ${moment(
              isManual && row.sano?.manualLog?.date
                ? row.sano.manualLog.date
                : manualUploadItem.date
            ).format("DD/MM/YYYY hh:mm")}`}</label>
          </div>
        );
      } else if (row.sano?.status === "error") {
        return (
          <div className="flex flex-col gap-1 py-1">
            <ButtonIcon
              className="w-fit flex py-1 px-2 gap-1 flex-row justify-center items-center text-white bg-orange-400 hover:bg-orange-300"
              text="transfert manuel"
              textClassName="texts-xs"
              style={{ borderRadius: 5 }}
              icon={<FaFileExport />}
              IconSize={10}
              onClick={async () => await handleManualUpload(row._id)}
            />
            {/*<ButtonIcon
              className="flex py-1 px-2 gap-1 flex-row justify-center items-center text-white bg-light hover:opacity-75"
              text="transfert auto"
              textClassName="texts-xs text-center"
              style={{ borderRadius: 5 }}
              icon={<FaFileExport />}
              IconSize={10}
              onClick={async () => {}}
            />*/}
          </div>
        );
      }
    },
  },
];

const StatusButton = (status) => {
  if (status === "error") {
    return <FaTimes size={20} className="text-red-600" />;
  } else if ("transmitted" === status) {
    return <FaCheck size={20} className="text-green-600" />;
  } else if (status === "manual") {
    return <FaFileExport size={20} className="text-orange-400" />;
  } else if (status === "manual-transmitted") {
    return <FaFileExport size={20} className="text-green-400" />;
  } else {
    return <div className="font-semibold">-</div>;
  }
};

export default columns;
