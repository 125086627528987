import { useEffect, useState } from "react";

import { useModal } from "../../../context/modal-context";

import { getLabelPrescription } from "../../../utils/sign";

import Yousign from "../../../classes/Yousign";

import RenewalForm from "../RenewalForm";

const ModalRenewalPrescription = ({
  handleSign,
  prescription,
  prescriber,
  handleSignFinish,
}) => {
  const [signatureLink, setSignatureLink] = useState(null);
  const { setModalFullScreen, setModalLabel } = useModal();

  useEffect(() => {
    setModalLabel(null);
    if (signatureLink) {
      const yousign = new Yousign({
        signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: true,
        iFrameAttributes: {
          referrerPolicy: "origin-when-cross-origin",
        },
      });
      yousign.onStarted((data) => {
        console.log("Signer has opened the signature");
        console.log(data);
      });

      yousign.onSuccess((data) => {
        console.log("Signer has successfully signed the document");
        console.log(data);
      });

      yousign.onError((data) => {
        console.log("Signer encountered an error when signing");
        console.log(data);
      });

      yousign.onPing((data) => {
        console.log("Ping - The signature request is loaded");
        console.log(data);
      });

      yousign.onDeclined((data) => {
        console.log("Declined - The signer declined the signature");
        console.log(data);
      });

      yousign.onSignatureDone((data) => {
        //You can download the document with all signatures in it
        console.log("Signature Done - The signature request is completed");
        console.log(data);
      });
      setModalFullScreen(true);
    } else {
      setModalFullScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureLink]);

  return (
    <div className="flex flex-col justify-center w-full h-full p-3">
      <span className="font-bold uppercase text-start">
        renouvellement {getLabelPrescription(prescription.contract.plan.code)}
      </span>
      <div
        className={`flex flex-col gap-5 ${
          signatureLink ? "w-full h-full" : ""
        }`}
      >
        {signatureLink ? (
          <div className="flex flex-col w-full h-full gap-5 mt-3">
            <div id="iframe-container" className="w-full h-full" />
            <button
              type="submit"
              className="self-end px-3 py-1 font-semibold border rounded w-fit border-dark text-dark hover:bg-dark hover:text-white"
              onClick={handleSignFinish}
            >
              Fermer
            </button>
          </div>
        ) : (
          <RenewalForm
            prescription={prescription}
            prescriber={prescriber}
            onSubmit={handleSign}
            setSignatureLink={setSignatureLink}
          />
        )}
      </div>
    </div>
  );
};

export default ModalRenewalPrescription;
