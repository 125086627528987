import { useEffect, useState } from "react";

import { filtersToQueryString, updateFilters } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import TableUser from "../components/User/Table";
import UserSearchBar from "../components/User/UserSearchBar";

export default function UserPage() {
  const [users, setUsers] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userLimit, setUserLimit] = useState(25);
  const [userSort, setUserSort] = useState(`{"lastName":1, "firstName":1}`);
  const [userPage, setUserPage] = useState(1);
  const [searchValue, setSearchValue] = useState();

  const [userFilters, setUserFilters] = useState({
    and: [{ role: { $in: ["commercial", "director", "supervisor"] } }],
  });

  useEffect(() => {
    async function defaultValue() {
      await getUsers({
        limit: 25,
        sort: `{"lastName":1, "firstName":1}`,
        page: 1,
        filters: {
          and: [{ role: { $in: ["commercial", "director", "supervisor"] } }],
        },
      });
    }
    defaultValue();
  }, []);

  const getUsers = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });

      const response = await API.get(
        `/users?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (userId, data) => {
    try {
      await API.put(`/users/${userId}/reset-password`, data);
    } catch (error) {
      console.log(error);
    }
  };

  const searchUsers = async (value) => {
    let query = [];
    let word = null;
    if (/^[a-zA-Z0-9@.-éèîï]*$/.test(value) && !value.includes("&")) {
      word = value.trim();
      setSearchValue(word);
      query.push(
        `or={"firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"fullName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"email":{"$regex":"${word}", "$options": "i"}}`
      );
      await getUsers({
        limit: userLimit,
        sort: userSort,
        page: userPage,
        filters: userFilters,
        query,
      });
    }
  };

  const updateUserFilters = async (operator, filterName, operation, value) => {
    const filters = updateFilters(
      userFilters,
      operator,
      filterName,
      operation,
      value
    );

    setUserFilters(filters);
    setUserPage(1);
    await getUsers({
      limit: userLimit,
      sort: userSort,
      page: 1,
      filters,
    });
    return filters;
  };

  const handleSort = async (sort) => {
    setUserSort(sort);
    setUserPage(1);
    await getUsers({
      sort,
      limit: userLimit,
      page: 1,
      filters: userFilters,
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setUserLimit(limit);
    setUserPage(1);
    await getUsers({
      sort: userSort,
      limit,
      page: 1,
      filters: userFilters,
    });
    return limit;
  };

  const handlePage = async (page) => {
    setUserPage(page);
    await getUsers({
      sort: userSort,
      limit: userLimit,
      page,
      filters: userFilters,
    });
    return page;
  };

  return (
    <Gabarit label="Utilisateurs" className="flex flex-col gap-3">
      <UserSearchBar
        className="max-w-[400px]"
        searchValue={searchValue}
        onChange={searchUsers}
        placeholder="Rechercher un utilisateur"
      />
      <TableUser
        users={users}
        isLoading={isLoading}
        limit={userLimit}
        page={userPage}
        handleFilters={updateUserFilters}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
        handleUpdateUser={updateUser}
      />
    </Gabarit>
  );
}
