import { Link } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";

const NavList = ({ routes, isActive, className }) => {
  return (
    <ul className={className}>
      {routes.map((route, index) => {
        if (route.items && route.items.length > 0) {
          return <DropdownMenu key={index} route={route} />;
        } else {
          return (
            <li key={index} className="lg:hover:scale-105 ">
              <Link
                to={route.path}
                className={`font-semibold uppercase hover:text-secondary ${
                  isActive(`${route.path}`) ? "text-light" : "text-primary"
                }`}
              >
                {route.name}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default NavList;
