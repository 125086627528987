import { saveAs } from "file-saver";
import { useEffect, useState } from "react";

import { useModal } from "../context/modal-context";
import { filtersToQueryString, updateFilters } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import ModalCreatePrescriber from "../components/Prescriber/Modal/create";
import TablePrescriber from "../components/Prescriber/Table";
import UserSearchBar from "../components/User/UserSearchBar";

export default function PrescriberPage() {
  const [prescribers, setPrescribers] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [prescriberLimit, setPrescriberLimit] = useState(25);
  const [prescriberSort, setPrescriberSort] = useState(
    `{"user.lastName":1, "user.firstName":1}`
  );
  const [prescriberPage, setPrescriberPage] = useState(1);
  const [searchValue, setSearchValue] = useState();
  const [prescriberFilters, setPrescriberFilters] = useState({});
  const [isLoadingSync, setIsLoadingSync] = useState([]);

  const { showModal, setModalLabel, hideModal } = useModal();

  useEffect(() => {
    async function defaultValue() {
      await getPrescribers({
        limit: 25,
        sort: `{"user.lastName":1, "user.firstName":1}`,
        page: 1,
        filters: {},
      });
    }
    defaultValue();
  }, []);

  const getPrescribers = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });
      const response = await API.get(
        `/prescribers?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setPrescribers(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onClickButtonPrescriberCreate = async (prescriber) => {
    setModalLabel("Ajouter un prescripteur");
    showModal(
      <ModalCreatePrescriber
        prescriber={prescriber}
        isLoading={isCreateLoading}
        setIsLoading={setIsCreateLoading}
        onClick={createPrescriber}
      />
    );
  };

  const createPrescriber = async (data) => {
    try {
      await API.post(`/prescribers`, data);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePrescriber = async (id, data) => {
    try {
      await API.put(`/prescribers/${id}`, data);
      hideModal();
      await getPrescribers({
        limit: prescriberLimit,
        sort: prescriberSort,
        page: prescriberPage,
        filters: prescriberFilters,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const searchPrescribers = async (value) => {
    let query = [];
    let word = null;
    if (/^[a-zA-Z0-9@.-éèîï ]*$/.test(value) && !value.includes("&")) {
      word = value.trim();
      setSearchValue(word);
      query.push(
        `or={"user.firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"user.lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"user.fullName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"user.email":{"$regex":"${word}", "$options": "i"}}`
      );
      await getPrescribers({
        limit: prescriberLimit,
        sort: prescriberSort,
        page: prescriberPage,
        filters: prescriberFilters,
        query,
      });
    }
  };

  const updatePrescriberFilters = async (
    operator,
    filterName,
    operation,
    value
  ) => {
    const filters = updateFilters(
      prescriberFilters,
      operator,
      filterName,
      operation,
      value
    );

    setPrescriberFilters(filters);
    setPrescriberPage(1);
    await getPrescribers({
      limit: prescriberLimit,
      sort: prescriberSort,
      page: 1,
      filters,
    });
    return filters;
  };

  const handleSync = async (userBadge) => {
    try {
      setIsLoadingSync([...isLoadingSync, userBadge]);
      await API.post("/prescribers/sync", { userBadge });
      window.location.href = `/prescripteurs`;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSync([]);
    }
  };

  const getExportStat = async (type) => {
    try {
      setIsLoading(true);
      const response = await API.get(`/prescribers/export/login?type=${type}`, {
        responseType: "arraybuffer",
      });

      const fileName =
        response.headers["content-disposition"].split("filename=")[1];
      const fileType = response.headers["content-type"];
      saveAs(new Blob([response.data], { type: fileType }), fileName);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleSort = async (sort) => {
    setPrescriberSort(sort);
    setPrescriberPage(1);
    await getPrescribers({
      sort,
      limit: prescriberLimit,
      page: 1,
      filters: prescriberFilters,
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setPrescriberLimit(limit);
    setPrescriberPage(1);
    await getPrescribers({
      sort: prescriberSort,
      limit,
      page: 1,
      filters: prescriberFilters,
    });
    return limit;
  };

  const handlePage = async (page) => {
    setPrescriberPage(page);
    await getPrescribers({
      sort: prescriberSort,
      limit: prescriberLimit,
      page,
      filters: prescriberFilters,
    });
    return page;
  };

  return (
    <Gabarit
      label="Prescripteurs"
      className="flex flex-col gap-3"
      leftComponent={
        <button
          className="self-end px-3 py-1 text-white border rounded-md w-fit bg-primary hover:bg-light"
          onClick={onClickButtonPrescriberCreate}
        >
          Ajouter un prescripteur
        </button>
      }
    >
      <div className="flex justify-between gap-2 p-3 bg-white border rounded-xl">
        <label className="font-bold text-primary">
          Nombre de compte prescripteur vérifié:{" "}
          <span>{prescribers.totalLoginsCount}</span>
        </label>
        <button
          className="flex items-center gap-1 p-1 bg-green-600 rounded-xl w-fit hover:bg-green-500"
          onClick={async () => await getExportStat("prescriber")}
        >
          <img
            src="/icons/download.svg"
            alt="Statistique de signature"
            width={24}
            height={24}
            className="text-white"
          />
        </button>
      </div>

      <div className="flex flex-col gap-2 p-3 bg-white border rounded-xl">
        <label className="font-bold text-primary">Top 3 connexions:</label>
        {Array.isArray(prescribers.top)
          ? prescribers.top
              .map((element, index) => (
                <div key={index} className="">
                  <span className="font-bold">{index + 1} : </span>
                  {element.fullName} ({element.loginsCount} connexion)
                </div>
              ))
              .sort((a, b) => b.loginsCount > a.loginsCount)
          : null}
      </div>

      <UserSearchBar
        className="max-w-[400px]"
        searchValue={searchValue}
        onChange={searchPrescribers}
        placeholder="Rechercher un prescripteur"
      />
      <TablePrescriber
        prescribers={prescribers}
        isLoading={isLoading}
        limit={prescriberLimit}
        page={prescriberPage}
        handleFilters={updatePrescriberFilters}
        handleUpdatePrescriber={updatePrescriber}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
        handleSync={handleSync}
        isLoadingSync={isLoadingSync}
      />
    </Gabarit>
  );
}
