import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";

import { passwordErrorMessage, passwordPattern } from "../../utils";
import API from "../../utils/api";

import AuthGabarit from "../../components/AuthGabarit";
import Input from "../../components/Input";

export default function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const password = watch("password");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await API.post("/reset-password", { id, ...data });
      navigate("/login");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <AuthGabarit DisplayLoginButton>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        <p className="text-sm">
          Veuillez saisir dans le champ ci-dessous un nouveau mot de passe pour
          finaliser votre compte. Vous serez redirigé à la page de connexion.
        </p>
        <Input
          id="password"
          label="Mot de passe"
          placeholder="Veuillez saisir votre mot de passe"
          type="password"
          {...register("password", {
            required: "Le mot de passe est requis",
            pattern: {
              value: passwordPattern,
              message: passwordErrorMessage,
            },
          })}
          error={errors.password}
        />

        <Input
          id="confirmPassword"
          label="Confirmer mot de passe"
          placeholder="Veuillez saisir votre mot de passe"
          type="password"
          hidebutton="true"
          {...register("confirmPassword", {
            required: "Ce champ est requis",
            validate: (value) =>
              value === password || "Les mots de passe ne correspondent pas",
          })}
          error={errors.confirmPassword}
        />

        <button
          type="submit"
          className="px-10 py-1 mx-auto border rounded w-fit border-primary text-primary hover:bg-primary disabled:bg-transparent hover:text-white"
          disabled={loading}
        >
          {loading ? <Loader /> : "Valider"}
        </button>
      </form>
    </AuthGabarit>
  );
}
