import { useState } from "react";
import { Steps } from "rsuite";
import { TutoStep1, TutoStep2, TutoStep3, TutoStep4 } from "./stepTuto";

const ModalTuto = ({ onCloseModal }) => {
  const [step, setStep] = useState(0);
  const [nextButtonLabel, setNextButtonLabel] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const MAX_STEP = 3;

  const onChange = (nextStep) => {
    const currentSTEP =
      nextStep < 0 ? 0 : nextStep > MAX_STEP ? MAX_STEP : nextStep;
    if (nextStep === MAX_STEP) {
      setNextButtonLabel("Fermer");
    }
    if (nextStep === MAX_STEP + 1) {
      setButtonDisabled(true);
      onCloseModal();
    }
    setStep(currentSTEP);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => {
    onChange(step - 1);
    setNextButtonLabel("Suivant");
  };

  const StepsBar = () => {
    return (
      <Steps current={step} className="font-semibold" small={true}>
        <Steps.Item title="Information patient" />
        <Steps.Item title="Timeline" />
        <Steps.Item title="Intervention" />
        <Steps.Item title="Graphique" />
      </Steps>
    );
  };

  const CurrentBody = () => {
    switch (step) {
      case 0:
        return <TutoStep1 />;
      case 1:
        return <TutoStep2 />;
      case 2:
        return <TutoStep3 />;
      case 3:
        return <TutoStep4 />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="p-4 md:px-4 flex flex-col gap-5 min-w-[500px] min-h-[300px]">
        <StepsBar />
        <CurrentBody />
      </div>
      <div className="flex justify-between p-4">
        <button
          className={`rounded text-white py-1 px-5 ${
            step === 0 ? "bg-secondary" : "bg-primary hover:bg-light"
          }`}
          onClick={onPrevious}
          disabled={step === 0}
        >
          Précédent
        </button>
        <button
          className={`rounded  text-white py-1 px-5 flex justify-center items-center ${
            buttonDisabled ? "bg-secondary" : "bg-primary hover:bg-light"
          }`}
          onClick={() => onNext()}
          disabled={buttonDisabled}
        >
          {nextButtonLabel ? nextButtonLabel : "Suivant"}
        </button>
      </div>
    </div>
  );
};

export default ModalTuto;
