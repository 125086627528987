export default function NotFound() {
  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-100">
      <div className="text-center">
        <p className="text-2xl font-semibold text-primary">Page Non Trouvée</p>
        <h1 className="mt-2 text-6xl font-bold text-secondary">404</h1>
        <p className="mt-4 text-lg text-gray-700">
          Oups! La page que vous cherchez n'existe pas.
        </p>
        <a
          href="/"
          className="mt-6 inline-block px-6 py-2 text-sm font-semibold text-white bg-primary rounded hover:bg-light hover:text-white"
        >
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
}
