import { useForm } from "react-hook-form";
import { Loader } from "rsuite";

import Input from "../../Input";

import { passwordErrorMessage, passwordPattern } from "../../../utils";

const ModalUpdatePrescriber = ({
  onClick,
  prescriber = {
    _id: null,
    user: {
      lastName: null,
      firstName: null,
      mobilePhone: null,
      email: null,
      userBadge: null,
      password: null,
    },
    rpps: null,
    finess: null,
  },
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const password = watch("password");

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        await onClick(prescriber._id, data);
      })}
      className="flex flex-col p-4 md:p-5 gap-5"
    >
      <div className="grid gap-4 md:grid-cols-2">
        <Input
          id="lastName"
          label="NOM *"
          defaultValue={prescriber?.user?.lastName}
          placeholder={"Veuillez saisir un nom"}
          {...register("lastName", {
            required: "Le nom est requis.",
          })}
          error={errors.lastName}
        />
        <Input
          id="firstName"
          label="PRÉNOM *"
          defaultValue={prescriber?.user?.firstName}
          placeholder={"Veuillez saisir un prénom"}
          {...register("firstName", {
            required: "Le prénom est requis.",
          })}
          error={errors.firstName}
        />
        <Input
          label="Identifiant unique"
          defaultValue={prescriber?._id}
          disabled
          placeholder={"Veuillez saisir un identifiant unique"}
        />
        <Input
          label="RPPS *"
          defaultValue={prescriber?.rpps}
          placeholder={"Veuillez saisir un RPPS"}
          {...register("rpps", {
            required: "Le rpps est requis.",
            pattern: {
              value: /^\d{11}$/,
              message: "Le RPPS doit contenir exactement 11 chiffres",
            },
          })}
          error={errors.rpps}
        />
        <Input
          id="email"
          label="Email *"
          defaultValue={prescriber?.user?.email}
          placeholder={"Veuillez saisir une adresse email"}
          {...register("email", {
            required: "L'email est requis.",
          })}
          error={errors.email}
        />
        <Input
          label="Mobile"
          defaultValue={prescriber?.user?.mobilePhone}
          placeholder={"Veuillez saisir un numéro de téléphone"}
        />
        <Input
          id="password"
          label="Mot de passe"
          placeholder="Veuillez saisir votre mot de passe"
          type="password"
          {...register("password", {
            pattern: {
              value: passwordPattern,
              message: passwordErrorMessage,
            },
          })}
          error={errors.password}
        />

        <Input
          id="confirmPassword"
          label="Confirmer mot de passe"
          placeholder="Veuillez saisir votre mot de passe"
          type="password"
          hidebutton="true"
          {...register("confirmPassword", {
            validate: (value) =>
              value === password || "Les mots de passe ne correspondent pas",
          })}
          error={errors.confirmPassword}
        />
      </div>
      <div className="text-xs text-red-500">{passwordErrorMessage}</div>
      <div className="flex flex-col justify-center gap-2 md:col-span-2">
        <button
          type="submit"
          className="self-end px-3 py-1 font-semibold border rounded w-fit disabled:text-dark border-dark text-dark hover:bg-dark disabled:bg-transparent hover:text-white"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Loader /> : "Enregistrer les modifications"}
        </button>
      </div>
    </form>
  );
};

export default ModalUpdatePrescriber;
