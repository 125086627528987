import { FaSearch, FaUserAlt, FaUserMd } from "react-icons/fa";
import { AutoComplete, InputGroup } from "rsuite";

const SearchBar = ({
  searchItems = [],
  searchValue,
  onChange,
  onSelect,
  className,
  placeholder = "Votre recherche",
}) => {
  return (
    <div key="searchbar_user" className={`border-0 ${className}`}>
      <InputGroup inside className="border-0 shadow-none drop-shadow-none">
        <AutoComplete
          key="searchbar_user"
          placeholder={placeholder}
          data={searchItems}
          value={searchValue}
          menuClassName="relative z-20 mt-2"
          onChange={async (word) => await onChange(word)}
          onSelect={onSelect}
          renderMenuItem={(label, item) => (
            <span className="flex flex-row items-center gap-1">
              {item.role === "prescriber" ? (
                <FaUserMd color="var(--primary)" />
              ) : (
                <FaUserAlt color="var(--primary)" />
              )}
              {label}
            </span>
          )}
        />
        <InputGroup.Button size="lg" className="cursor-default hover:!bg-transparent focus:!bg-transparent">
          <FaSearch />
        </InputGroup.Button>
      </InputGroup>
    </div>
  );
};

export default SearchBar;
