import { useEffect, useState } from "react";
import _ from "lodash";

import { filtersToQueryString, removeFilters, updateFilters } from "../utils";
import API from "../utils/api";
import FileService from "../services/fileService";
import { FaFileExport, FaCheck, FaTimes } from "react-icons/fa";

import Gabarit from "../components/Gabarit";
import TablePrescription from "../components/Prescription/Table";
import TablePrescriptionFilters from "../components/Prescription/Table/TablePrescriptionFilters";

export default function UploadSignaturePage() {
  const [prescriptions, setPrescriptions] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [prescriptionLimit, setPrescriptionLimit] = useState(25);
  const [prescriptionSort, setPrescriptionSort] = useState(
    `{"prescriptionstartTime":-1, "prescriptionstartTime":-1}`
  );
  const [prescriptionPage, setPrescriptionPage] = useState(1);
  const [prescriptionFilters, setPrescriptionFilters] = useState({
    and: [{ isSigned: { $eq: true } }, { "sano.status": { $in: ["error"] } }],
  });
  const [currentSanoStatus, setCurrentSanoStatus] = useState(["error"]);

  const [userItems, setUserItems] = useState();
  const [userQuery, setUserQuery] = useState(null);

  const [manualUploadItems, setManualUploadItems] = useState([]);

  const fileService = new FileService();

  useEffect(() => {
    async function defaultValue() {
      await getPrescriptions({
        limit: prescriptionLimit,
        sort: prescriptionSort,
        page: prescriptionPage,
        filters: prescriptionFilters,
      });
    }
    defaultValue();
  }, [
    prescriptionLimit,
    prescriptionSort,
    prescriptionPage,
    prescriptionFilters,
  ]);

  const getPrescriptions = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });
      const response = await API.get(
        `/prescriptions?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setPrescriptions(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const upPrescriptionStatus = async (prescriptionId, status) => {
    try {
      const result = await API.put(
        `/prescriptions/${prescriptionId}/sano-status`,
        { status }
      );
      return result.data;
    } catch (error) {}
  };

  const getUsers = async (query) => {
    try {
      const response = await API.get(
        `/users?limit=${5}&page=${1}&sort=${`{"lastName":-1,"firstName":-1}`}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }`
      );
      return response?.data?.docs || [];
    } catch (error) {}
  };

  const updatePrescriptionFilters = async (
    operator,
    filterName,
    operation,
    value
  ) => {
    const filters = updateFilters(
      prescriptionFilters,
      operator,
      filterName,
      operation,
      value
    );

    setPrescriptionFilters(filters);
    setPrescriptionPage(1);
    await getPrescriptions({
      limit: prescriptionLimit,
      sort: prescriptionSort,
      page: 1,
      filters,
      query: userQuery,
    });
    return filters;
  };

  const removePrescriptionFilter = async (
    groupOperator,
    fieldToRemove,
    operationToRemove
  ) => {
    const filters = removeFilters(
      prescriptionFilters,
      groupOperator,
      fieldToRemove,
      operationToRemove
    );
    setPrescriptionFilters(filters);
    setPrescriptionPage(1);
    await getPrescriptions({
      limit: prescriptionLimit,
      page: 1,
      sort: prescriptionSort,
      filters,
      query: userQuery,
    });
    // Retourner l'objet filters mis à jour
    return filters;
  };

  const removeAllPrescriptionFilters = async () => {
    setUserItems([]);
    setPrescriptionFilters({
      and: [{ isSigned: { $eq: true } }],
    });
    setPrescriptionPage(1);
    await getPrescriptions({
      limit: prescriptionLimit,
      sort: prescriptionSort,
      page: 1,
      filters: {
        and: [{ isSigned: { $eq: true } }],
      },
      query: [],
    });
  };

  const searchUsers = async (word) => {
    let items = [];
    let filters = [];
    const checkString = word && word !== " ";
    if (checkString && /^[a-zA-Z ]+$/.test(word)) {
      filters.push(`and={"role":{"$in":["patient","prescriber"]}}`);
      filters.push(
        `or={"firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"fullName":{"$regex":"${word}", "$options": "i"}}`
      );
    } else if (checkString && /^\d+$/.test(word)) {
      filters.push(`or={"roleId":{"$regex":"${parseInt(word, 10)}"}}`);
    }

    const response = filters.length > 0 ? await getUsers(filters) : [];

    response.map((item) =>
      items.push({
        label: `${item.fullName} (${item.roleId})`,
        value: item.roleId,
        role: item.role,
        groupBy: item.role,
        loading: false,
      })
    );

    return { items };
  };

  const removeUserItem = async (value) => {
    const query = [];
    let items = userItems;

    items = items.filter((item) => item !== value);

    if (items.length > 0) {
      items.map((item) => query.push(`or={"prescriberId":{"$eq":${item}}}`));
      items.map((item) => query.push(`or={"patientId":{"$eq":${item}}}`));
    }
    setUserItems(items);
    setUserQuery(query);

    setPrescriptionPage(1);
    await getPrescriptions({
      limit: prescriptionLimit,
      page: 1,
      sort: prescriptionSort,
      filters: prescriptionFilters,
      query,
    });
  };

  const updateUserItem = async (updatedItems) => {
    const query = [];

    if (updatedItems.length > 0) {
      updatedItems.map((item) => {
        query.push(`or={"patientId":{"$eq":${item}}}`);
        query.push(`or={"prescriberId":{"$eq":${item}}}`);
        return item;
      });
    }
    setUserItems(updatedItems);
    setUserQuery(query);

    setPrescriptionPage(1);
    await getPrescriptions({
      limit: prescriptionLimit,
      page: 1,
      sort: prescriptionSort,
      filters: prescriptionFilters,
      query,
    });
  };

  const handleDownloadOrdo = async (prescriptionId) => {
    await fileService.downloadOrdo(prescriptionId);
  };

  const handleManualUpload = async (prescriptionId) => {
    const result = await upPrescriptionStatus(prescriptionId, "manual");
    if (result) {
      setManualUploadItems(
        _.uniqBy(
          [
            ...manualUploadItems,
            {
              id: prescriptionId,
              ...result,
            },
          ],
          "id"
        )
      );
    }
  };

  const handleSort = async (sort) => {
    setPrescriptionSort(sort);
    setPrescriptionPage(1);
    await getPrescriptions({
      sort,
      limit: prescriptionLimit,
      page: 1,
      filters: prescriptionFilters,
      query: userQuery,
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setPrescriptionLimit(limit);
    setPrescriptionPage(1);
    await getPrescriptions({
      sort: prescriptionSort,
      limit,
      page: 1,
      filters: prescriptionFilters,
      query: userQuery,
    });
    return limit;
  };

  const handlePage = async (page) => {
    setPrescriptionPage(page);
    await getPrescriptions({
      sort: prescriptionSort,
      limit: prescriptionLimit,
      page,
      filters: prescriptionFilters,
      query: userQuery,
    });
    return page;
  };

  return (
    <Gabarit
      label="Transmission signature"
      className="flex flex-col items-center gap-3"
    >
      <div className="flex flex-col gap-2">
        <TablePrescriptionFilters
          className="w-fit mx-auto"
          isLoading={isLoading}
          filters={prescriptionFilters}
          onSearchUsers={searchUsers}
          userItems={userItems}
          onChangeUser={updateUserItem}
          onRemoveUser={removeUserItem}
          handleFilters={updatePrescriptionFilters}
          handleRemoveFilter={removePrescriptionFilter}
          handleRemoveAllFilters={removeAllPrescriptionFilters}
          currentSanoStatus={currentSanoStatus}
          setCurrentSanoStatus={setCurrentSanoStatus}
        />
        <div className="flex items-center gap-2 m-2 text-xs">
          <label className="text-primary underline">
            Légende état du transfert vers SANO:
          </label>
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <FaTimes className="text-red-600" />
              <span>Erreur de transmission</span>
            </div>
            <div className="flex items-center gap-1">
              <FaCheck className="text-green-600" />
              <span>Transmission réussie</span>
            </div>
            <div className="flex items-center gap-1">
              <FaFileExport className="text-orange-600" />
              <span>Transmis manuellement</span>
            </div>
            <div className="flex items-center gap-1">
              <FaFileExport className="text-green-600" />
              <span>Transmis manuellement et synchronisé</span>
            </div>
          </div>
        </div>
      </div>

      <TablePrescription
        prescriptions={prescriptions}
        isLoading={isLoading}
        limit={prescriptionLimit}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
        handleDownloadOrdo={handleDownloadOrdo}
        manualUploadItems={manualUploadItems}
        handleManualUpload={handleManualUpload}
      />
    </Gabarit>
  );
}
