import { BsSquareFill } from "react-icons/bs";

import Dropdown from "./Dropdown";

const TabContainer = ({
  data,
  activeTab,
  setActiveTab,
  className,
  children,
}) => {
  return (
    <>
      <div className={`border-b border-b-[color:#D3D7DA] ${className}`}>
        <ul className="hidden lg:flex bg-[color:#EEF1F4] rounded-t-lg w-fit m-0">
          {data.map((option, index) => (
            <li
              key={index}
              className={`relative block cursor-pointer truncate py-2 px-5 font-semibold ${
                activeTab === option.value
                  ? "lg:text-white bg-light rounded-t-lg"
                  : "hover:opacity-50"
              }`}
              onClick={() => setActiveTab(option.value)}
            >
              <div className="flex items-center justify-center space-x-2">
                <BsSquareFill
                  size={13}
                  className={`mr-2 ${
                    option.value === activeTab ? "text-primary" : "text-white"
                  } rounded`}
                />
                <span className="text-sm">{option.label}</span>
              </div>
              {activeTab === option.value ? (
                <span className="absolute inset-x-0 w-full h-px -bottom-px bg-light"></span>
              ) : null}
            </li>
          ))}
        </ul>
        <Dropdown
          className="px-2 mb-5 lg:hidden"
          options={data}
          active={activeTab}
          setActive={setActiveTab}
        />
      </div>
      {children}
    </>
  );
};

export default TabContainer;
