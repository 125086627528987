import { useEffect, useState } from "react";

import { useAuth } from "../context/auth-context";
import { filtersToQueryString, removeFilters, updateFilters } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import TablePatient from "../components/Patient/Table";
import TablePatientFilters from "../components/Patient/Table/TablePatientFilters";

export default function PatientsPage() {
  const [patients, setPatients] = useState({ docs: [], page: 1, totalDocs: 0 });
  const [prescriberItems, setPrescriberItems] = useState([]);
  const [pumps, setPumps] = useState([]);
  const [tutoMode, setTutoMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientLimit, setPatientLimit] = useState(25);
  const [patientSort, setPatientSort] = useState(
    `{"lastName":1, "firstName":1}`
  );
  const [patientPage, setPatientPage] = useState(1);
  const [patientFilters, setPatientFilters] = useState({});
  const [prescriberQuery, setPrescriberQuery] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    async function defaultValue() {
      await getPatients({
        limit: 25,
        sort: `{"lastName":1, "firstName":1}`,
        page: 1,
        filters: {},
        query: null,
      });
      await getPumps();
    }
    defaultValue();
  }, []);

  const getPatients = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });

      const response = await API.get(
        `/patients?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setPatients(response.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const getPrescribers = async (query = []) => {
    try {
      const response = await API.get(
        `/prescribers/search?limit=${5}&page=${1}&sort=${'{"lastName":1, "firstName":1}'}&${query.join(
          "&"
        )}`
      );
      return response?.data?.docs || [];
    } catch (error) {}
  };

  const getPumps = async () => {
    try {
      const response = await API.get(`/devices/pumps`);
      setPumps(response.data);
    } catch (error) {}
  };

  const updatePatientFilters = async (
    operator,
    filterName,
    operation,
    value
  ) => {
    const filters = updateFilters(
      patientFilters,
      operator,
      filterName,
      operation,
      value
    );

    setPatientFilters(filters);
    setPatientPage(1);
    await getPatients({
      limit: patientLimit,
      sort: patientSort,
      page: 1,
      filters,
      query: prescriberQuery,
    });
    return filters;
  };

  const removePatientFilter = async (
    groupOperator,
    fieldToRemove,
    operationToRemove
  ) => {
    const filters = removeFilters(
      patientFilters,
      groupOperator,
      fieldToRemove,
      operationToRemove
    );
    setPatientFilters(filters);
    setPatientPage(1);
    await getPatients({
      limit: patientLimit,
      page: 1,
      sort: patientSort,
      filters,
      query: prescriberQuery,
    });
    // Retourner l'objet filters mis à jour
    return filters;
  };

  const removeAllPatientFilters = async () => {
    setPrescriberItems([]);
    setPatientFilters({});
    setPatientPage(1);
    await getPatients({
      limit: patientLimit,
      sort: patientSort,
      page: 1,
      filters: {},
      query: [],
    });
  };

  const searchPrescribers = async (word) => {
    let items = [];
    let filters = [];
    const checkString = word && word !== " ";
    if (checkString && /^[a-zA-Z ]+$/.test(word)) {
      filters.push(
        `or={"user.firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"user.lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"user.fullName":{"$regex":"${word}", "$options": "i"}}`
      );
    } else if (checkString && /^\d+$/.test(word)) {
      filters.push(`or={"_id":{"$regex":"${parseInt(word, 10)}"}}`);
    }

    const response = filters.length > 0 ? await getPrescribers(filters) : [];
    response.map((item) =>
      items.push({
        label: `${item.user.fullName} (${item._id})`,
        value: item._id,
        role: item.user.role,
      })
    );

    return { loading: false, items, groupBy: "prescriber" };
  };

  const removePrescriberItem = async (removedItem) => {
    const query = [];
    let items = prescriberItems;

    items = items.filter((item) => item !== removedItem);

    if (items.length > 0) {
      items.map((item) => query.push(`or={"prescriberId":{"$eq":${item}}}`));
      query.push(`or={"prescriberIds":{"$in":[${items}]}}`);
    }
    setPrescriberItems(items);
    setPrescriberQuery(query);

    setPatientPage(1);
    await getPatients({
      limit: patientLimit,
      page: 1,
      sort: patientSort,
      filters: patientFilters,
      query,
    });
  };

  const updatePrescriberItem = async (updatedItems) => {
    const query = [];

    if (updatedItems.length > 0) {
      updatedItems.map((item) =>
        query.push(`or={"prescriberId":{"$eq":${item}}}`)
      );
      query.push(`or={"prescriberIds":{"$in":[${updatedItems}]}}`);
    }
    setPrescriberItems(updatedItems);
    setPrescriberQuery(query);

    setPatientPage(1);
    await getPatients({
      limit: patientLimit,
      page: 1,
      sort: patientSort,
      filters: patientFilters,
      query,
    });
  };

  const handleSort = async (sort) => {
    setPatientSort(sort);
    setPatientPage(1);
    await getPatients({
      sort,
      limit: patientLimit,
      page: 1,
      filters: patientFilters,
      query: prescriberQuery,
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setPatientLimit(limit);
    setPatientPage(1);
    await getPatients({
      sort: patientSort,
      limit,
      page: 1,
      filters: patientFilters,
      query: prescriberQuery,
    });
    return limit;
  };

  const handlePage = async (page) => {
    setPatientPage(page);
    await getPatients({
      sort: patientSort,
      limit: patientLimit,
      page,
      filters: patientFilters,
      query: prescriberQuery,
    });
    return page;
  };

  return (
    <Gabarit
      label="Patients"
      className="flex flex-col gap-3 md:flex-row"
      tutoMode={tutoMode}
      setTutoMode={setTutoMode}
    >
      <TablePatientFilters
        pumps={pumps}
        tutoMode={tutoMode}
        isLoading={isLoading}
        page={patientPage}
        filters={patientFilters}
        handleFilters={updatePatientFilters}
        handleRemoveFilter={removePatientFilter}
        handleRemoveAllFilters={removeAllPatientFilters}
        searchByPrescriber={["admin", "commercial", "supervisor", "director"].includes(
          user.role
        )}
        prescriberItems={prescriberItems}
        onSearchPrescribers={searchPrescribers}
        onChangePrescriber={updatePrescriberItem}
        onRemovePrescriber={removePrescriberItem}
        searchByRenewalStatus={[
          "admin",
          "prescriber",
          "commercial",
          "director",
          "supervisor",
        ].includes(user.role)}
        firstLogin={user.firstLogin}
      />
      <TablePatient
        patients={patients}
        tutoMode={tutoMode}
        isLoading={isLoading}
        limit={patientLimit}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
      />
    </Gabarit>
  );
}
