import moment from "moment";
import { useState } from "react";
import { FaCheck, FaFileExport, FaTimes } from "react-icons/fa";

import { useModal } from "../../context/modal-context";
import FileService from "../../services/fileService";

import ModalRenewalPrescription from "../Prescription/Modal/RenewalModal";

import { getLabelPrescription } from "../../utils/sign";

const PrescriptionCard = ({
  prescriptions = [],
  renewalMode = false,
  handleSign,
  handleSignFinish,
  prescriber,
  hasSignAccess = false,
  className,
  viewStatusSano = false,
}) => {
  const { showModal } = useModal();

  const PrescriptionItem = ({ prescription }) => {
    const [downloadBtnHover, setDownloadBtnHover] = useState(false);

    const fileService = new FileService();

    const handleDownloadOrdo = async () => {
      await fileService.downloadOrdo(prescription._id);
    };

    const handleOpenSignModal = async (prescription) => {
      showModal(
        <ModalRenewalPrescription
          prescription={prescription}
          handleSign={handleSign}
          prescriber={prescriber}
          handleSignFinish={handleSignFinish}
        />
      );
    };

    const DownloadIcon = ({ hover }) => {
      const iconSrc = hover
        ? "/icons/renewal-download-alt.svg"
        : "/icons/renewal-download.svg";
      return (
        <img
          src={iconSrc}
          className="w-5 h-5 max-w-fit"
          alt="Télécharger la prescription"
        />
      );
    };

    return (
      <div id={`prescription-${prescription._id}`}>
        <div className="flex items-center justify-between gap-5">
          <div>
            <div className="mb-2 font-semibold">
              {prescription.contract?.activity} -{" "}
              {getLabelPrescription(prescription.contract?.plan?.code)}
            </div>
            <div>
              <label className="font-semibold">Début :</label>{" "}
              <span>{moment(prescription.startDate).format("DD/MM/YYYY")}</span>
            </div>
            <div>
              <label className="font-semibold">Fin :</label>{" "}
              <span>{moment(prescription.endDate).format("DD/MM/YYYY")}</span>
            </div>
          </div>
          {renewalMode ? (
            <div className="w-fit h-fit">
              {hasSignAccess &&
                prescription.isToRenew &&
                !prescription.isSigned && (
                  <button
                    className="px-3 py-1 font-semibold border rounded w-fit border-dark text-dark hover:bg-dark hover:text-white"
                    onClick={() => handleOpenSignModal(prescription)}
                  >
                    Renouveler
                  </button>
                )}
              {prescription.isSigned &&
                prescription.status?.id === "Waiting" && (
                  <div className="flex flex-col items-end justify-start gap-4">
                    <p className="p-1 px-2 text-xs font-light border rounded-full border-validate-dark bg-validate">
                      Signée
                    </p>
                    <button
                      className="flex items-center justify-between w-full gap-1 px-3 py-1 font-semibold border rounded border-dark text-dark hover:bg-dark hover:text-white"
                      onMouseEnter={() => setDownloadBtnHover(true)}
                      onMouseLeave={() => setDownloadBtnHover(false)}
                      onClick={handleDownloadOrdo}
                    >
                      <p>Télécharger</p>
                      <DownloadIcon hover={downloadBtnHover} />
                    </button>
                  </div>
                )}
            </div>
          ) : (
            prescription.isSigned &&
            prescription.status?.id === "Accepted" && (
              <div className="flex flex-col items-end justify-start gap-4">
                <p className="p-1 px-2 text-xs font-light border rounded-full border-validate-dark bg-validate">
                  Signée
                </p>
                <div className="column is-narrow w-fit">
                  <button
                    className="flex items-center justify-between w-full gap-1 px-3 py-1 font-semibold border rounded border-dark text-dark hover:bg-dark hover:text-white"
                    onMouseEnter={() => setDownloadBtnHover(true)}
                    onMouseLeave={() => setDownloadBtnHover(false)}
                    onClick={handleDownloadOrdo}
                  >
                    <p>Télécharger</p>
                    <DownloadIcon hover={downloadBtnHover} />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const StatusButton = ({ status }) => {
    if (status === "error") {
      return <FaTimes size={20} className="text-red-600" />;
    } else if ("transmitted" === status) {
      return <FaCheck size={20} className="text-green-600" />;
    } else if (status === "manual") {
      return <FaFileExport size={20} className="text-orange-400" />;
    } else if (status === "manual-transmitted") {
      return <FaFileExport size={20} className="text-green-400" />;
    } else {
      return <div className="font-semibold">-</div>;
    }
  };

  const PrescriptionSanoStatus = ({ prescription }) => {
    if (!prescription.isSigned) {
      return;
    }
    if (prescription.sano && prescription.sano.status) {
      let statusLabel = "";
      const status = prescription.sano.status;
      if (status === "transmitted") statusLabel = "Transmission SANO réussie";
      if (status === "manual") statusLabel = "Transmis manuellement";
      if (status === "manual-transmitted")
        statusLabel = "Transmission (manuel.) SANO réussie";
      if (status === "error") statusLabel = "Erreur de transmission SANO";
      return (
        <div className="flex justify-end items-center gap-2 mt-2 mx-2 text-primary text-xs">
          <span>{statusLabel}</span>
          <StatusButton status={prescription.sano.status} />
        </div>
      );
    }
  };

  return (
    <div
      className={`shadow-xl rounded-xl h-fit${
        className ? " " + className : ""
      }`}
    >
      <div className="p-2 px-3 font-semibold text-white bg-primary rounded-t-xl">
        <label className="uppercase">
          prescriptions {renewalMode ? "à renouveler" : ""}
        </label>
      </div>
      <div className="bg-white p-3 rounded-b-xl min-h-[50px]">
        {Array.isArray(prescriptions) && prescriptions.length > 0 ? (
          prescriptions.map((prescription, index) => (
            <span key={prescription._id}>
              <PrescriptionItem prescription={prescription} />
              {index !== prescriptions.length - 1 && (
                <hr
                  className="mt-1 mb-4 has-background-dark"
                  style={{ height: 1 }}
                />
              )}
              {viewStatusSano && (
                <PrescriptionSanoStatus prescription={prescription} />
              )}
            </span>
          ))
        ) : (
          <p className="font-semibold text-center">
            {`Aucune prescription${
              renewalMode ? " à renouveler" : ""
            } n'est renseignée pour ce
            patient.`}
          </p>
        )}
      </div>
    </div>
  );
};

export default PrescriptionCard;
