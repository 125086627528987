import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.withCredentials = true;

const API = axios.create({ baseURL: `${process.env.REACT_APP_API_CREDENTIAL}` });

API.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    if (response.data && response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  async (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (Array.isArray(error.response.data.errors)) {
        error.response.data.errors.map((err) => toast.error(err.message));
      }
    }
    return Promise.reject(error);
  }
);

export default API;
