import { useForm } from "react-hook-form";
import { Loader } from "rsuite";

import { passwordErrorMessage, passwordPattern } from "../../../utils";
import Input from "../../Input";

const ModalUpdateUser = ({
  onClick,
  user = {
    _id: null,
    lastName: null,
    firstName: null,
    mobilePhone: null,
    email: null,
    role: null,
    roleInfo: null,
    password: null,
  },
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const password = watch("password");

  return (
    <div className="flex flex-col gap-2 p-4 md:p-5">
      <div className="flex flex-col gap-2 sm:flex-row">
        <div className="flex flex-col w-full gap-2 md:w-1/2">
          <Input
            label="NOM *"
            defaultValue={user?.lastName}
            disabled
            placeholder={"Veuillez saisir un nom"}
          />
          <Input
            label="Email *"
            defaultValue={user?.email}
            disabled
            placeholder={"Veuillez saisir une adresse email"}
          />
        </div>
        <div className="flex flex-col w-full gap-2 md:w-1/2">
          <Input
            label="PRÉNOM *"
            defaultValue={user?.firstName}
            disabled
            placeholder={"Veuillez saisir un prénom"}
          />
          <Input
            label="Mobile"
            defaultValue={user.mobilePhone}
            disabled
            placeholder={"Veuillez saisir un numéro de téléphone"}
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(async (data) => await onClick(user._id, data))}
        className="flex flex-col gap-2"
      >
        <div className="grid gap-4 md:grid-cols-2">
          <Input
            id="password"
            label="Mot de passe"
            placeholder="Veuillez saisir votre mot de passe"
            type="password"
            {...register("password", {
              required: "Le mot de passe est requis",
              pattern: {
                value: passwordPattern,
                message: passwordErrorMessage,
              },
            })}
            error={errors.password}
          />

          <Input
            id="confirmPassword"
            label="Confirmer mot de passe"
            placeholder="Veuillez saisir votre mot de passe"
            type="password"
            hidebutton="true"
            {...register("confirmPassword", {
              required: "Ce champ est requis",
              validate: (value) =>
                value === password || "Les mots de passe ne correspondent pas",
            })}
            error={errors.confirmPassword}
          />
        </div>

        <div className="text-xs text-red-500">{passwordErrorMessage}</div>
        <div className="flex flex-col justify-center gap-2 md:col-span-2">
          <button
            type="submit"
            className="self-end px-3 py-1 font-semibold border rounded w-fit disabled:text-dark border-dark text-dark hover:bg-dark disabled:bg-transparent hover:text-white"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Loader /> : "Enregistrer les modifications"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModalUpdateUser;
