import { useEffect, useState } from "react";

import { filtersToQueryString } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import TableLog from "../components/Log/Table";

export default function LogsPage() {
  const [logs, setLogs] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [logLimit, setLogLimit] = useState(25);
  const [logSort, setLogSort] = useState(`{"date":-1}`);
  const [logPage, setLogPage] = useState(1);

  useEffect(() => {
    async function defaultValue() {
      await getLogs({
        limit: 25,
        sort: `{"date":-1}`,
        page: 1,
        filters: {},
      });
    }
    defaultValue();
  }, []);

  const getLogs = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      filters = { and: [{ type: { $eq: "synchronisation" } }] };
      const filterQueryString = filtersToQueryString({ filters });
      const response = await API.get(
        `/logs?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setLogs(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = async (sort) => {
    setLogSort(sort);
    setLogPage(1);
    await getLogs({
      sort,
      limit: logLimit,
      page: 1,
      filters: {},
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setLogLimit(limit);
    setLogPage(1);
    await getLogs({
      sort: logSort,
      limit,
      page: 1,
      filters: {},
    });
    return limit;
  };

  const handlePage = async (page) => {
    setLogPage(page);
    await getLogs({
      sort: logSort,
      limit: logLimit,
      page,
      filters: {},
    });
    return page;
  };

  return (
    <Gabarit label="Logs" className="flex flex-col gap-3 md:flex-row">
      <TableLog
        logs={logs}
        isLoading={isLoading}
        limit={logLimit}
        page={logPage}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
      />
    </Gabarit>
  );
}
