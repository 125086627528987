import moment from "moment";
import { IoMdSettings } from "react-icons/io";

const columns = (onClickButtonUserUpdate) => [
  {
    name: "ID",
    selector: (row) => `${row.roleId}`,
    sortable: true,
    sortfield: "roleId",
    width: "70px",
    cell: (row) => <span data-tag="allowRowEvents">{row.roleId}</span>,
  },
  {
    name: "Utilisateur",
    selector: (row) => `${row.fullName}`,
    sortable: true,
    sortfield: "lastName",
    width: "300px",
    cell: (row) => <span>{`${row.lastName} ${row.firstName}`}</span>,
  },
  {
    name: "rôle",
    selector: (row) => `${row.role}`,
    sortable: true,
    sortfield: "role",
    width: "120px",
    cell: (row) => <span>{row.role}</span>,
  },
  {
    name: "email",
    selector: (row) => `${row.email}`,
    sortable: true,
    sortfield: "email",
    width: "300px",
    cell: (row) => <span>{row.email}</span>,
  },
  {
    name: "1er connexion",
    selector: (row) => `${row.firstLogin}`,
    sortable: true,
    sortfield: "firstLogin",
    width: "200px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {row.firstLogin
          ? moment(row.firstLogin).format("DD/MM/YYYY HH:mm")
          : "-"}
      </span>
    ),
  },
  {
    name: "Der. connexion",
    selector: (row) => `${row.lastLogin}`,
    sortable: true,
    sortfield: "lastLogin",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {row.lastLogin ? moment(row.lastLogin).format("DD/MM/YYYY HH:mm") : "-"}
      </span>
    ),
  },
  {
    name: null,
    sortable: false,
    sortField: null,
    width: "70px",
    cell: (row) => (
      <div
        key={`user_${row._id}`}
        target="_blank"
        data-tip="update"
        className="mx-1"
      >
        <IoMdSettings
          className="text-white rounded cursor-pointer bg-light hover:opacity-70"
          size={28}
          onClick={async () => onClickButtonUserUpdate(row)}
          tooltip-id={`toolPres_${row._id}`}
          data-tip="editer"
        />
      </div>
    ),
  },
];

export default columns;
