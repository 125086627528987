import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { useModal } from "../context/modal-context";

const Modal = () => {
  const {
    isModalVisible,
    modalContent,
    hideModal,
    modalLabel,
    modalFullScreen,
  } = useModal();

  useEffect(() => {
    // Empêcher le scroll du corps de la page derrière la modale
    const originalOverflow = document.body.style.overflow;
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isModalVisible]);

  if (!isModalVisible) return null;

  return (
    <div
      className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm"
      //onClick={hideModal}
    >
      <div
        className={`bg-white rounded-lg shadow-lg overflow-auto max-h-[95%] max-w-[95%] relative ${
          modalFullScreen ? "h-full w-full" : ""
        }`}
        onClick={(e) => e.stopPropagation()} // Empêche le clic de se propager au conteneur de fond
      >
        <div
          className={`flex items-center w-full p-3 ${
            modalLabel ? "border-b border-b-neutral-lighter" : "absolute"
          }`}
        >
          <label className="flex-1 font-semibold text-primary">
            {modalLabel}
          </label>
          <AiOutlineClose
            className="cursor-pointer justify-self-end text-primary hover:text-light"
            size={24}
            onClick={hideModal}
          />
        </div>
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;
