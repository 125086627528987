export default function CookiePolicyPage() {
  return (
    <div className="flex flex-col gap-5 bg-white p-3">
      <p className="text-2xl font-bold uppercase text-primary">
        Informations concernant l'utilisation des cookies
      </p>
      <p className="italic">Dernière mise à jour : 21/11/2023</p>
      <div>
        <p>
          Lors de la consultation de notre site (
          {process.env.REACT_APP_FRONTEND}) des informations sont susceptibles
          d'être enregistrées dans des fichiers "Cookies" installés par nous ou
          par des tiers dans votre ordinateur, tablette ou téléphone mobile.
        </p>
        <p>
          Cette page permet de comprendre ce qu’est un Cookie, comment nous
          l’utilisons et quels sont les moyens dont vous disposez pour effectuer
          des choix à l'égard de ces cookies.
        </p>
      </div>
      <div className="flex flex-col gap-2"></div>
      <div>
        <p className="text-xl font-semibold uppercase mb-2 text-dark">
          QU'EST-CE QU'UN COOKIE ?
        </p>
        <div>
          <p>
            Le terme « cookie » est un terme générique qui désigne tant un
            fichier texte déposé dans un espace dédié du disque dur de votre
            terminal (ordinateur, tablette, téléphone mobile ou tout autre
            appareil optimisé pour Internet), lors de la consultation d’un
            contenu ou d’une publicité en ligne, que les technologies similaires
            permettant de lire ou écrire des informations sur le terminal d’un
            utilisateur et de suivre en combinaison avec l’usage de cookies son
            activité sur Internet ou dans une application. Certains cookies sont
            indispensables à l'utilisation du Site, d'autres permettent
            d'optimiser et de personnaliser les contenus affichés.
          </p>
          <p>
            Le fichier Cookie contient certaines informations, comme un
            identifiant unique, le nom du Site, ainsi que des chiffres et des
            caractères. Ce fichier Cookie ne peut être lu que par son émetteur.
            Il permet, à son émetteur, pendant sa durée de validité, de
            reconnaître le terminal concerné à chaque fois que ce terminal
            accède à un contenu numérique comportant des Cookies du même
            émetteur et de se souvenir d’informations, comme les préférences des
            utilisateurs, les informations précédemment saisies par
            l’utilisateur dans des formulaires présents sur le Site. Certains
            cookies peuvent aussi être utilisés pour stocker le contenu d'un
            panier d'achat, d'autres pour enregistrer les paramètres de langue
            d'un site, d'autres encore pour faire de la publicité ciblée.
          </p>
          <p>
            Un Cookie ne permet pas d’identifier directement un utilisateur (il
            ne contient ni noms ou prénoms), mais le navigateur de votre
            terminal, généralement mobile, utilisée. Il permet toutefois de
            suivre les actions d’un même utilisateur à l’aide de l’identifiant
            unique contenu dans le fichier Cookie. Le terme « cookies » est
            également utilisé de façon générique pour désigner d’autres
            technologies similaires comme les balises web ou pixel invisible qui
            sont des petites images numériques invisibles à l’utilisateur
            intégrées sur les pages internet ou dans les courriels et qui
            associées au dépôt de cookies permettent de suivre et analyser la
            navigation d’un même utilisateur.
          </p>
        </div>
      </div>

      <div>
        <p className="text-xl font-semibold text-dark">
          À QUOI SERVENT LES COOKIES UTILISÉS PAR LE SITE ?
        </p>
        <div>
          <p>
            Le site utilise uniquement des Cookies techniques pour rendre
            possible votre navigation sur le Site, assurer la sécurité du Site
            et vous permettre d’accéder aux différents produits et services du
            Site.
          </p>
          <p>
            Les Cookies techniques comprennent les cookies strictement
            nécessaires indispensables à la navigation sur notre Site (comme les
            identifiants de session, la version de votre système d’exploitation)
            qui vous permettent d'utiliser les principales fonctionnalités du
            Site, ainsi que les cookies de fonctionnalités qui permettent de
            vous donner accès à des fonctionnalités spécifiques en particulier
            de vous reconnaître, de signaler votre passage sur telle ou telle
            page et ainsi d’améliorer votre confort de navigation : adapter la
            présentation du site aux préférences d'affichage de votre terminal
            (langue utilisée, résolution d'affichage), mémoriser les mots de
            passe et autres informations relatives à un formulaire que vous avez
            rempli sur le Site (inscription ou accès à l’espace adhérents). Les
            Cookies techniques permettent également de mettre en œuvre des
            mesures de sécurité (c’est le cas par exemple lorsqu’il vous est
            demandé de vous connecter à nouveau à l’espace adhérent après un
            certain laps de temps).
          </p>
          <p>
            Ces cookies ont une durée de vie très courte, pour les cookies
            strictement nécessaires, le temps de la session, et pour les cookies
            de fonctionnalité le temps de la session, quelques heures et au
            maximum, d'une année.
          </p>
        </div>
      </div>

      <div>
        <p className="text-xl font-semibold text-dark">
          VOS CHOIX CONCERNANT LES COOKIES
        </p>
        <div>
          <p>
            En utilisant notre Site, vous consentez à l’utilisation des Cookies
            précités, ces cookies étant indispensables au fonctionnement, à la
            navigation sur le Site ou à l’utilisation d’un service à votre
            demande, ils ne peuvent pas être refusés via un bandeau cookies du
            site web.
          </p>
        </div>
      </div>

      <div>
        <p className="text-xl font-semibold text-dark">
          MISE A JOUR DE CETTE POLITIQUE
        </p>
        <div>
          <p>
            Nous sommes susceptibles de modifier cette politique de temps à
            autres pour refléter les changements dans nos pratiques, pour
            préciser d’avantage nos pratiques ou pour assurer le respect de la
            réglementation.Dans ce cas, vous en serez informés par un message
            sur ce site vous invitant à consulter la politique mise à jour.
          </p>
        </div>
      </div>
    </div>
  );
}
