import { createContext, useEffect, useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

import API from "../../utils/api";

import { useAuth } from "../../context/auth-context";
import UserSearchBar from "../User/UserSearchBar";
import MobileNavList from "./MobileNavList";
import NavList from "./NavList";
import UserMenu from "./UserMenu";

export const NavContext = createContext(null);

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [routes, setRoutes] = useState([]);
  const { user, logout } = useAuth();
  const location = useLocation();
  const mobileNavRef = useRef(null);

  const navigation = useNavigate();

  useEffect(() => {
    async function defaultValue() {
      const initRoutes = [{ name: "patients", path: "/" }];
      if (user.role === "admin") {
        initRoutes.push({
          name: "administration",
          items: [
            { name: "prescripteurs", path: "/prescripteurs" },
            { name: "utilisateurs", path: "/utilisateurs" },
            { name: "transmission signature", path: "/upload-signature" },
            { name: "monitoring", path: "/monitoring" },
            { name: "logs", path: "/logs" },
            { name: "statistiques", path: "/statistiques" },
          ],
        });
      }
      setRoutes(initRoutes);
    }
    defaultValue();
  }, [user.role]);

  useEffect(() => {
    const controller = new AbortController();
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
      { signal: controller.signal }
    );
    return () => controller.abort();
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileNavRef.current &&
        !mobileNavRef.current.contains(event.target)
      ) {
        setOpenNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsMenuOpen]);

  const isActive = (pathname) => location.pathname === pathname;

  const getUsers = async (query) => {
    try {
      const response = await API.get(
        `/patients?limit=${10}&page=${1}&sort=${`{"lastName":-1,"firstName":-1}`}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }`
      );
      setSearchItems(response?.data?.docs || []);
      return response?.data?.docs || [];
    } catch (error) {}
  };

  const searchUsers = async (word) => {
    let items = [];
    let filters = [];
    setSearchValue(word);

    const checkString = word && word !== " ";
    if (checkString && /^[a-zA-Z ]+$/.test(word) && !word.includes("&")) {
      filters.push(
        `or={"firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"fullName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"email":{"$regex":"${word}", "$options": "i"}}`
      );
    } else if (checkString && !isNaN(word)) {
      filters.push(`or={"_id":${word}}`);
    }

    const response = filters.length > 0 ? await getUsers(filters) : [];
    response.map((item) =>
      items.push({
        label: `${item.fullName} (${item._id})`,
        value: `${item.fullName} (${item._id})`,
        role: "patient",
        _id: item._id,
      })
    );

    setSearchItems(items);
  };

  return (
    <NavContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        isUserMenuOpen,
        setIsUserMenuOpen,
        isActive,
      }}
    >
      <nav className="relative z-10 flex px-5 py-2 border-b backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border-b-primary">
        <a href="/" className="hover:scale-105">
          <img
            src="/logo.svg"
            className="max-w-none"
            alt="Logo"
            width={64}
            height={64}
          />
        </a>

        <NavList
          routes={routes}
          isActive={isActive}
          className="items-center hidden w-full ml-20 space-x-20 lg:flex"
        />

        <div className="items-center hidden lg:flex">
          {user.role !== "patient" ? (
            <UserSearchBar
              className="lg:min-w-[400px] ml-auto lg:mr-5"
              searchItems={searchItems}
              searchValue={searchValue}
              onChange={searchUsers}
              onSelect={(value, item) => {
                switch (item.role) {
                  case "patient":
                    navigation(`/patients/${item._id}`);
                    break;
                  case "prescriber":
                    navigation(`/prescripteurs/${item._id}`);
                    break;
                  case "commercial":
                    navigation(`/utilisateurs/${item._id}`);
                    break;
                  case "director":
                    navigation(`/utilisateurs/${item._id}`);
                    break;
                  case "supervisor":
                    navigation(`/utilisateurs/${item._id}`);
                    break;
                  default:
                    return value;
                }
              }}
            />
          ) : null}

          <UserMenu
            handleLogout={logout}
            isActive={isActive}
            className="flex items-center"
          />
        </div>

        <div
          className="items-end ml-auto cursor-pointer lg:hidden text-primary hover:text-light hover:scale-105"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? <IoMdClose size={25} /> : <FaBars size={25} />}
        </div>

        {openNav ? (
          <MobileNavList
            ref={mobileNavRef}
            routes={routes}
            isActive={isActive}
            handleLogout={logout}
            className="flex items-center"
          />
        ) : null}
      </nav>
    </NavContext.Provider>
  );
};

export default Navbar;
