const ContractLabel = ({ type }) => {
  let config = null;
  if (type === "EXPERTIS") {
    config = { imgSrc: "/icons/expertis.png", bgColor: "#000063" };
  } else if (type === "INTENSIS") {
    config = { imgSrc: "/icons/intensis.png", bgColor: "#ffab19" };
  } else if (type === "TELESIS") {
    config = { imgSrc: "/icons/telesis.png", bgColor: "#E8477D" };
  }

  if (type) {
    return (
      <div className="flex gap-2">
        {config && type !== "EXPERTIS" && (
          <div
            className={`flex flex-row gap-2 items-center p-2 text-white rounded bg-[${config.bgColor}] justify-center cursor-default max-h-[38px]`}
            style={{ backgroundColor: config.bgColor }}
          >
            <img src={config.imgSrc} className="h-[26px]" alt="contract" />
            {type}
          </div>
        )}

        <div
          className={`flex flex-row gap-2 items-center p-2 text-white rounded bg-[${"#000063"}] justify-center cursor-default max-h-[38px]`}
          style={{ backgroundColor: "#000063" }}
        >
          <img src="/icons/expertis.png" className="h-[26px]" alt="contract" />
          EXPERTIS
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`flex flex-row gap-2 items-center p-2 text-white rounded bg-[${"#000063"}] justify-center cursor-default max-h-[38px]`}
        style={{ backgroundColor: "#000063" }}
      >
        <img src="/icons/expertis.png" className="h-[26px]" alt="contract" />
        EXPERTIS
      </div>
    );
  }
};

export default ContractLabel;
