import { createContext, useContext, useEffect, useState } from "react";

import API from "../utils/api";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await API.get("/users/me");
        setUser(response?.data || null);
      } catch (error) {
        console.error("Erreur lors de la vérification du token", error);
      } finally {
        setLoading(false);
      }
    };
    if (
      ![
        "/login",
        "/forgot-password",
        "/validate-email",
        "/politique-cookies",
      ].includes(window.location.pathname) &&
      !window.location.pathname.includes("/reset-password") &&
      !window.location.pathname.includes("/verify-account")
    ) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const { data } = await API.post("/login", { email, password });
      if (data.user) {
        setUser(data.user);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await API.post("/logout");
      setUser(null);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
