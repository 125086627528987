import moment from "moment";

const TreatmentCard = ({ traitement, className }) => {
  const { installation, diabeteType, insuline, firstHbA1c, hba1c } =
    traitement || {};
  const formatDate = (date) =>
    date ? moment(date).format("DD/MM/YYYY") : "--";
  const renderValue = (value) => (value ? value : "--");

  return (
    <div
      className={`shadow-xl rounded-xl h-fit${
        className ? " " + className : ""
      }`}
    >
      <div className="p-2 px-3 font-semibold text-white bg-primary rounded-t-xl">
        <label className="uppercase">traitement</label>
      </div>
      <div className="bg-white p-3 rounded-b-xl flex flex-col gap-2 min-h-[50px]">
        <div>
          <label className="font-semibold">Date installation :</label>{" "}
          <span>{formatDate(installation)}</span>
        </div>
        <div>
          <label className="font-semibold">Type de diabète :</label>{" "}
          <span>{renderValue(diabeteType?.label)}</span>
        </div>
        <div>
          <label className="font-semibold">Insuline (DTQ) :</label>{" "}
          <span>
            {insuline} {insuline !== "--" ? "UI" : null}
          </span>
        </div>
        <div>
          <label className="font-semibold">Première HbA1c (%) connue :</label>{" "}
          <span>{firstHbA1c}</span>
        </div>
        <div>
          <label className="font-semibold">Dernière HbA1c (%) :</label>{" "}
          <span>{hba1c}</span>
        </div>
      </div>
    </div>
  );
};

export default TreatmentCard;
