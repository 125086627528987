import { useForm } from "react-hook-form";
import { Loader } from "rsuite";

import Input from "../../Input";

const ModalCreatePrescriber = ({ onClick }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm();

  return (
    <form
      onSubmit={handleSubmit(async (data) => await onClick(data))}
      className="flex flex-col gap-5 p-4 md:p-5"
    >
      <Input
        id="login"
        label="Identifiant"
        placeholder="Veuillez saisir un identifiant"
        type="text"
        {...register("login", {
          required: "L'identifiant est requis",
        })}
        error={errors.login}
      />
      <Input
        id="password"
        label="Mot de passe"
        placeholder="Veuillez saisir votre mot de passe"
        type="password"
        {...register("password", {
          required: "Le mot de passe est requis",
        })}
        error={errors.password}
      />

      <div className="mx-auto md:col-span-2">
        <button
          type="submit"
          className="self-end px-3 py-1 font-semibold border rounded w-fit disabled:text-dark border-dark text-dark hover:bg-dark disabled:bg-transparent hover:text-white"
          disabled={isSubmitting || !isValid}
        >
          {isSubmitting ? <Loader /> : "Ajouter"}
        </button>
      </div>
    </form>
  );
};

export default ModalCreatePrescriber;
