import { GoChevronDown } from "react-icons/go";
import DataTable from "react-data-table-component";

import columns from "./columns";
import customStyles from "./customStyles";

const InterventionTable = ({ interventions = [] }) => (
  <DataTable
    columns={columns()}
    data={interventions}
    customStyles={customStyles}
    defaultSortFieldId={1}
    defaultSortAsc={false}
    sortIcon={<GoChevronDown color="var(--light)" />}
    striped
    highlightOnHover
    dense
    theme="solarized"
    noDataComponent={
      <span className="text-neutral-dark my-5">
        Il n'y a pas d'interventions pour ce patient.
      </span>
    }
    paginationComponentOptions={{
      rowsPerPageText: "Intervention par page:",
      rangeSeparatorText: "sur",
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: "All",
    }}
  />
);

export default InterventionTable;
