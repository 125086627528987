const DeviceCard = ({ device, lastIntervention, className }) => {
  const renderModel = (model) =>
    typeof model === "string" ? model.replace("POMPE ", "") : "--";
  const renderValue = (value) => (value ? value : "--");
  const getCatheter = () => {
    return renderValue(
      lastIntervention &&
        lastIntervention.catheterType &&
        typeof lastIntervention.catheterType === "string"
        ? lastIntervention.catheterType.replace(new RegExp('"', "g"), "")
        : null
    );
  };
  const getSensorType = () => {
    return renderValue(
      lastIntervention &&
        lastIntervention.sensorType &&
        typeof lastIntervention.sensorType === "string"
        ? lastIntervention.sensorType.replace(new RegExp('"', "g"), "")
        : null
    );
  };

  return (
    <div
      className={`shadow-xl rounded-xl h-fit${
        className ? " " + className : ""
      }`}
    >
      <div className="p-2 px-3 font-semibold text-white bg-primary rounded-t-xl">
        <label className="uppercase">MATÉRIEL</label>
      </div>
      <div className="bg-white p-3 rounded-b-xl flex flex-col gap-1 min-h-[50px]">
        {device ? (
          <span className="flex flex-col gap-2">
            <div>
              <label className="font-semibold">Modèle de pompe :</label>{" "}
              <span>{renderModel(device.model)}</span>
            </div>
            <div>
              <label className="font-semibold">Modèle de cathéter :</label>{" "}
              <span>{getCatheter()}</span>
            </div>
            <div>
              <label className="font-semibold">Modèle de capteur :</label>{" "}
              <span>{getSensorType()}</span>
            </div>
          </span>
        ) : (
          <p className="font-semibold text-center">
            Aucun matériel n'est renseigné pour ce patient.
          </p>
        )}
      </div>
    </div>
  );
};

export default DeviceCard;
