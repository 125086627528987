// Navbar/DropdownMenu.js
import { useContext, useEffect, useRef } from "react";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { NavContext } from "./index";

const UserMenu = ({ handleLogout, className }) => {
  const { isUserMenuOpen, setIsUserMenuOpen, isActive } =
    useContext(NavContext);
  const UserDropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        UserDropdownRef.current &&
        !UserDropdownRef.current.contains(event.target)
      ) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsUserMenuOpen]);

  const toggleDropdown = () => setIsUserMenuOpen(!isUserMenuOpen);

  return (
    <div
      ref={UserDropdownRef}
      className={`relative  font-semibold uppercase ${className}`}
    >
      <FaRegUser
        size={20}
        onClick={toggleDropdown}
        className="cursor-pointer lg:hover:scale-105 text-primary"
      />
      {isUserMenuOpen && (
        <div className="absolute right-0 inset-y-9">
          <ul className="flex flex-col items-center">
            <li
              className={`lg:hover:scale-105 w-full cursor-pointer p-2 ${
                isActive(`/mon-compte`)
                  ? "bg-light"
                  : "bg-primary hover:bg-secondary"
              }`}
              onClick={() => navigate("/mon-compte")}
            >
              <div className="text-center text-white uppercase">mon compte</div>
            </li>
            <li
              className="w-full p-2 text-white bg-red-500 cursor-pointer lg:hover:scale-105"
              onClick={handleLogout}
            >
              <div className="text-center text-white uppercase hover:text-white">
                déconnexion
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
