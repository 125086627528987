import { Controller } from "react-hook-form";
import { InputNumber, Radio, RadioGroup } from "rsuite";

const EquipmentSet = ({
  label,
  name,
  required,
  checked,
  setChecked,
  setValue,
  control,
  defaultValue,
  className,
}) => {
  return (
    <div className={`flex items-center py-3 ${className}`}>
      <div className={checked ? "w-1/2 border-r-2 pr-4" : ""}>
        <p className="">
          Souhaitez-vous prescrire des{" "}
          <span className="font-bold">{label}</span> ?
          {required && <span className="text-red-500">*</span>}
        </p>
        <RadioGroup
          name="radioList"
          inline
          value={checked.toString()}
          onChange={(value) => {
            if (value === "true") {
              setChecked(true);
              setValue(name, defaultValue);
            } else if (value === "false") {
              setChecked(false);
              setValue(name, 0);
            }
          }}
          className="flex gap-5"
        >
          <Radio value="true" checked>
            Oui
          </Radio>
          <Radio value="false">Non</Radio>
        </RadioGroup>
      </div>
      <div className={checked ? "w-1/2 pl-4" : ""}>
        {checked && (
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <div className="flex flex-col gap-2">
                <p className="font-semibold">
                  Quantité de boîtes par mois{" "}
                  <span className="text-red-500">*</span>
                </p>
                <div className="flex items-center gap-2">
                  <div style={{ width: 80 }}>
                    <InputNumber
                      value={field.value}
                      step={1}
                      min={1}
                      max={3}
                      defaultValue={field.value}
                      onChange={(value) => field.onChange(Number(value))}
                      size="sm"
                    />
                  </div>
                  <p>Boîte(s)</p>
                </div>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default EquipmentSet;
