import moment from "moment";

const columns = () => [
  {
    name: "Début synchro",
    selector: (row) => `${row.startDate}`,
    sortable: true,
    sortfield: "startDate",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.startDate).format("DD/MM/YYYY HH:mm")}
      </span>
    ),
  },
  {
    name: "Fin synchro",
    selector: (row) => `${row.endDate}`,
    sortable: true,
    sortfield: "endDate",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.endDate).format("DD/MM/YYYY HH:mm")}
      </span>
    ),
  },
  {
    name: "Durée",
    selector: (row) => `${row.duration}`,
    sortable: true,
    sortfield: "duration",
    cell: (row) => <span data-tag="allowRowEvents">{row.durationString}</span>,
  },
  {
    name: "Nb. patient total",
    selector: (row) => `${row.patients?.total}`,
    sortable: true,
    sortfield: "patient.total",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="text-xs">
        {row.patients?.total}
      </span>
    ),
  },
  {
    name: "Nb. nouveau patient",
    selector: (row) => `${row.patients?.inserted}`,
    sortable: true,
    sortfield: "patient.inserted",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="text-xs">
        {row.patients?.inserted}
      </span>
    ),
  },
  {
    name: "Nb. patient modifié",
    selector: (row) => `${row.patients?.modified}`,
    sortable: true,
    sortfield: "patient.modified",
    cell: (row) => (
      <span data-tag="allowRowEvents">{row.patients?.modified}</span>
    ),
  },
  {
    name: "Nb. patient renouvelable",
    selector: (row) => `${row.patients?.renewal}`,
    sortable: true,
    sortfield: "patient.renewal",
    cell: (row) => <span data-tag="allowRowEvents">{row.patients?.renewal}</span>,
  },
  {
    name: "Nb. requête échouée",
    selector: (row) => `${row.failedRequest?.total}`,
    sortable: true,
    sortfield: "failedRequest.total",
    cell: (row) => (
      <span data-tag="allowRowEvents">{row.failedRequest?.total}</span>
    ),
  },
];

export default columns;
