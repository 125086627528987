import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "rsuite";

import { checkTypeDocDemo } from "../utils";
import API from "../utils/api";

import NotFound from "../components/NotFound";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Hook personnalisé pour récupérer les paramètres de l'URL
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FilePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileInfo, setFileInfo] = useState({
    file: null,
    fileType: null,
    fileName: "doc_isis_diabete2.pdf",
  });

  const { type, id } = useParams();
  const query = useQuery();

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        /*fileNameGenerator: (file) => {
          return fileInfo.fileType === "application/pdf"
            ? fileInfo.fileName
            : "doc_isis_diabete.pdf";
        },*/
      },
    },
  });

  useEffect(() => {
    const fetchFile = async () => {
      let fileData = { file: null, fileType: null, fileName: null };

      if (process.env.REACT_APP_DEMO) {
        fileData = {
          file: "/" + checkTypeDocDemo(query.get("type")),
          fileName: "doc",
          fileType: "application/pdf",
        };
      } else {
        const response = await getFile(type, id, query.get("doc"));
        if (response) {
          fileData = {
            file: response.fileUrl,
            fileType: response.fileType,
            fileName: response.fileName,
          };
        }
      }

      setFileInfo(fileData);
      setIsLoading(false);
    };

    fetchFile();
  }, [type, id, query]);

  const getFile = async (type, id, docId) => {
    try {
      if (type === "intervention") {
        const response = await API.get(
          `/interventions/${id}/document/${docId}`,
          {
            responseType: "blob",
          }
        );
        if (
          response &&
          response.data &&
          response.data.size > 200 &&
          response.headers["content-type"] === "application/pdf"
        ) {
          return {
            fileUrl: URL.createObjectURL(response.data),
            fileType: response.headers["content-type"],
            fileName: response.headers["content-disposition"]?.split('"')[1],
          };
        }
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  };

  const downloadFile = () => {
    const { file, fileType, fileName } = fileInfo;
    if (file && fileType && fileName) {
      saveAs(new Blob([file], { type: fileType }), fileName);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Loader className="p-4" />
      </div>
    );
  } else if (!fileInfo.file) {
    return <NotFound />;
  } else if (fileInfo.fileType !== "application/pdf") {
    downloadFile();
    return null;
  } else {
    return (
      <Worker workerUrl="/pdfWorker.js">
        <div>
          <Viewer
            fileUrl={fileInfo.file}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>
    );
  }
};

export default FilePage;
