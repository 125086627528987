import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import FileService from "../../../services/fileService";
import { getInterventionPicto } from "../../../utils";

const columns = () => {
  const fileService = new FileService();
  return [
    {
      name: "Date",
      sortField: "date",
      selector: (row) => `${row.date}`,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div className="text-primary font-semibold">
            {moment(row.date).format("DD/MM/YYYY")}
          </div>
        </div>
      ),
    },
    {
      name: "Statut",
      sortField: "status.label",
      selector: (row) => `${row.status.label}`,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>{row.status.label}</div>
        </div>
      ),
    },
    {
      name: "Type d'intervention",
      sortField: "type.label",
      selector: (row) => `${row.type.label}`,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>{row.type.label}</div>
        </div>
      ),
    },
    /*
    {
      name: "Intervenant",
      sortField: "agent",
      selector: (row) => `${row.agent}`,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>{row.agent}</div>
        </div>
      ),
    },
    */
    {
      name: "Fichiers associés",
      sortable: false,
      sortField: "",
      cell: (row) => {
        if (Array.isArray(row.documents)) {
          const docButtons = [];
          row.documents
            .sort((a, b) => {
              const order = {
                Courrier: 1,
                Évaluation: 2,
                "Téléchargement Dispositif": 3,
              };
              return order[a.importType] - order[b.importType];
            })
            .map((doc) => {
              if (doc.extension === "pdf") {
                docButtons.push(
                  <Link
                    key={`Cdoc_${doc.id}`}
                    to={`/file/intervention/${row._id}?doc=${doc.id}`}
                    target="_blank"
                  >
                    <img
                      src={`/icons/${getInterventionPicto(doc.importType)}`}
                      className="p-1"
                      alt="download"
                      width={35}
                      height={35}
                      data-tooltip-id={`doc_${doc.id}`}
                      data-tooltip-content={
                        process.env.REACT_APP_DEMO ? "doc.pdf" : doc.name
                      }
                      data-tooltip-place="left"
                    />
                    <Tooltip id={`doc_${doc.id}`} />
                  </Link>
                );
              } else {
                docButtons.push(
                  <div
                    key={`Cdoc_${doc.id}`}
                    data-tip={process.env.REACT_APP_DEMO ? "doc.pdf" : doc.name}
                    className="mx-1 cursor-pointer"
                    onClick={async () =>
                      await fileService.download(
                        "interventions",
                        row._id,
                        doc.id
                      )
                    }
                  >
                    <img
                      src={`/icons/${getInterventionPicto(doc.importType)}`}
                      className="p-1"
                      alt="download"
                      width={35}
                      height={35}
                      data-tooltip-id={`doc_${doc.id}`}
                      data-tooltip-content={
                        process.env.REACT_APP_DEMO ? "doc.pdf" : doc.name
                      }
                      data-tooltip-place="left"
                    />
                    <Tooltip id={`doc_${doc.id}`} />
                  </div>
                );
              }
              return doc;
            });

          return (
            <div className="flex flex-wrap gap-3">
              {docButtons.map((component, index) => (
                <div key={index}>{component}</div>
              ))}
            </div>
          );
        }
      },
    },
  ];
};

export default columns;
