import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Chart } from "react-chartjs-2";
import {
  config1,
  config2,
  config3,
  config4,
  dataConfig1,
  dataConfig2,
  dataConfig3,
  dataConfig4,
} from "./data";

const MetricGraph = ({ metrics }) => {
  Chart.register(ChartDataLabels);

  if (metrics) {
    return (
      <>
        {Object.keys(metrics.labels).length === 0 && (
          <div className="w-full my-5 text-center text-neutral-dark">
            Aucune donnée médicale n'a été renseignée pour ce patient.
          </div>
        )}
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
          <div>
            <Bar
              data={dataConfig1(metrics)}
              options={config1}
              plugins={ChartDataLabels}
            />
          </div>
          <div>
            <Bar
              data={dataConfig2(metrics)}
              options={config2}
              plugins={ChartDataLabels}
            />
          </div>
          <div>
            <Bar
              data={dataConfig3(metrics)}
              options={config3}
              plugins={ChartDataLabels}
            />
          </div>
          <div>
            <Bar
              data={dataConfig4(metrics)}
              options={config4}
              plugins={ChartDataLabels}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full my-5 text-center text-neutral-dark">
      Aucune donnée médicale n'a été renseignée pour ce patient.
    </div>
  );
};

export default MetricGraph;
