const TutoStep3 = () => (
  <div className="flex justify-center lg:h-[50vh] lg:w-[60vw]">
    <img
      src="/tuto/tuto-intervention.svg"
      className="w-full h-full"
      alt="tuto"
    />
  </div>
);

export default TutoStep3;
