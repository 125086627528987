import { useState } from "react";

import MetricTab from "../Intervention/Metric/tab";
import InterventionTab from "../Intervention/tab";
import TabContainer from "../TabContainer";
import TimelineTab from "../Timeline/tab";

const PatientTab = ({ className, patient, metrics }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <TabContainer
      data={[
        {
          value: 1,
          label: "Historique des prises en charge",
        },
        {
          value: 2,
          label: "Historique interventions",
        },
        {
          value: 3,
          label: "Graphiques données médicales",
        },
      ]}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      className={className}
    >
      <div className="p-5 pt-0">
        {activeTab === 1 &&
          (patient.contracts &&
          patient.contracts.length > 0 &&
          patient.interventions &&
          patient.interventions.length > 0 ? (
            <TimelineTab
              firstContract={patient.firstContract}
              contracts={patient.contracts}
              interventions={patient.interventions || []}
            />
          ) : (
            <div className="w-full my-5 text-center text-neutral-dark">
              Il n'y a pas encore de prise en charge pour ce patient.
            </div>
          ))}
        {activeTab === 2 && (
          <InterventionTab interventions={patient.interventions} />
        )}
        {activeTab === 3 && <MetricTab metrics={metrics} />}
      </div>
    </TabContainer>
  );
};

export default PatientTab;
