import { saveAs } from "file-saver";

import API from "../utils/api";

class FileService {
  async download(type, typeId, docId) {
    try {
      const response = await API.get(`/${type}/${typeId}/document/${docId}`, {
        responseType: "blob",
      });
      if (response.data) {
        const fileName = response.headers["content-disposition"].split('"')[1];
        const fileType = response.headers["content-type"];
        saveAs(new Blob([response.data], { type: fileType }), fileName);
      }
    } catch (error) {
      return error.response.data.message;
    }
  }
  async downloadOrdo(prescriptionId) {
    try {
      const response = await API.get(
        `/prescriptions/${prescriptionId}/document`,
        {
          responseType: "blob",
        }
      );
      if (response.data) {
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "");
        const fileType = response.headers["content-type"];
        saveAs(new Blob([response.data], { type: fileType }), fileName);
      }
    } catch (error) {
      return error.response.data.message;
    }
  }
}

export default FileService;
