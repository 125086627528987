import { FaChevronLeft } from "react-icons/fa6";

const BackgroundSection = () => (
  <div className="flex flex-row justify-between absolute z-0 h-screen w-screen">
    <div className="w-0 md:w-0 lg:w-[60%] h-[67.5%] lg:pl-5">
      <div className="bg-secondary h-full w-full"></div>
    </div>
    <div className="w-full lg:w-[40%] h-screen flex flex-col justify-end">
      <GroupImage />
      <PersoImage />
    </div>
  </div>
);

const GroupImage = () => (
  <div className="w-[35%] h-[35%] lg:h-[25%] bg-primary flex justify-center p-2 ml-auto">
    <img src="icons/group.svg" alt="group" />
  </div>
);

const PersoImage = () => (
  <div className="w-[65%] h-[40%] bg-light flex items-end">
    <img src="icons/perso.svg" alt="perso" className="mx-auto" />
  </div>
);

const Logo = () => (
  <div className="invisible lg:visible absolute top-3 left-3 md:left-20">
    <img
      src="/logo.png"
      alt="logo"
      className="w-[130px] h-[56px] lg:w-[230px] lg:h-[126px]"
    />
  </div>
);

const Card = ({ className, children }) => (
  <div
    className={`flex flex-col gap-4 bg-white rounded-lg shadow-xl p-10 max-w-[590px] mt-7 ${className}`}
  >
    <WelcomeText />
    {children}
  </div>
);

const WelcomeText = () => (
  <span className="flex flex-col justify-center text-center text-2xl font-bold text-primary gap-0">
    <span>Bienvenue sur le portail</span>
    {process.env.REACT_APP_IS_PATIENT ? (
      <span>patient ISIS DIABÈTE</span>
    ) : (
      <span>prescripteur ISIS DIABÈTE</span>
    )}
  </span>
);

const BackToLogin = () => (
  <a
    href="/login"
    className="text-sm text-blue-800 underline w-fit flex flex-row items-center space-x-1"
  >
    <FaChevronLeft />
    <span className="underline">Retour</span>
  </a>
);

function AuthGabarit({ children, className, DisplayLoginButton }) {
  return (
    <div className="relative bg-white">
      <BackgroundSection />
      <section className="flex items-start justify-center h-screen w-screen relative px-2 pt-5">
        <Logo />
        <Card className={className}>
          {children}
          {DisplayLoginButton ? <BackToLogin /> : null}
        </Card>
      </section>
    </div>
  );
}

export default AuthGabarit;
