import { saveAs } from "file-saver";
import { useEffect, useState } from "react";

import { filtersToQueryString } from "../utils";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import TableStat from "../components/Statistic/Table";

export default function StatsPage() {
  const [stats, setStats] = useState({
    docs: [],
    page: 1,
    totalDocs: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [statLimit, setLogLimit] = useState(25);
  const [statSort, setLogSort] = useState(`{"startDate":-1, "startDate":-1}`);
  const [statPage, setLogPage] = useState(1);

  useEffect(() => {
    async function defaultValue() {
      await getStats({
        limit: 25,
        sort: `{"startDate":-1, "startDate":-1}`,
        page: 1,
        filters: {},
      });
    }
    defaultValue();
  }, []);

  const getStats = async ({ limit, sort, page, filters, query }) => {
    try {
      setIsLoading(true);
      const filterQueryString = filtersToQueryString({ filters });

      const response = await API.get(
        `/statistics?limit=${limit}&page=${page}&sort=${sort}${
          Array.isArray(query) && query.length > 0 ? "&" + query.join("&") : ""
        }${filterQueryString ? "&" + filterQueryString : ""}`
      );
      setStats(response.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const getExportStat = async (type) => {
    try {
      setIsLoading(true);
      const response = await API.get(`/statistics/export?type=${type}`, {
        responseType: "arraybuffer",
      });

      const fileName =
        response.headers["content-disposition"].split("filename=")[1];
      const fileType = response.headers["content-type"];
      saveAs(new Blob([response.data], { type: fileType }), fileName);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleSort = async (sort) => {
    setLogSort(sort);
    setLogPage(1);
    await getStats({
      sort,
      limit: statLimit,
      page: 1,
      filters: {},
    });
    return sort;
  };

  const handleLimit = async (limit) => {
    setLogLimit(limit);
    setLogPage(1);
    await getStats({
      sort: statSort,
      limit,
      page: 1,
      filters: {},
    });
    return limit;
  };

  const handlePage = async (page) => {
    setLogPage(page);
    await getStats({
      sort: statSort,
      limit: statLimit,
      page,
      filters: {},
    });
    return page;
  };

  return (
    <Gabarit label="Statistiques" className="flex flex-col gap-3">
      <div className="flex flex-col gap-2 p-3 bg-white border border-primary rounded-xl">
        <label className="font-bold text-primary ">
          Statistique de signature
        </label>
        <button
          className="flex items-center gap-1 p-1 px-2 bg-green-600 rounded w-fit hover:bg-green-500"
          onClick={async () => await getExportStat("signature")}
        >
          <img
            src="/icons/download.svg"
            alt="Statistique de signature"
            width={24}
            height={24}
            className="text-white"
          />
          <p className="text-sm font-bold text-white">Exporter les données</p>
        </button>
      </div>

      <TableStat
        stats={stats}
        isLoading={isLoading}
        limit={statLimit}
        page={statPage}
        handleLimit={handleLimit}
        handleSort={handleSort}
        handlePage={handlePage}
      />
    </Gabarit>
  );
}
