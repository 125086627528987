import moment from "moment";
import { Loader } from "rsuite";
import { IoMdSettings } from "react-icons/io";
import { AiOutlineCloudSync } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const columns = (
  onClickButtonPrescriberUpdate,
  handleSyncPrescriber,
  isLoadingSync
) => {
  return [
    {
      name: "ID",
      selector: (row) => `${row._id}`,
      width: "70px",
      sortable: true,
      sortfield: "_id",
      cell: (row) => <span data-tag="allowRowEvents">{row._id}</span>,
    },
    {
      name: "Prescripteurs",
      selector: (row) => `${row.user.fullName}`,
      sortable: true,
      sortfield: "user.lastName",
      cell: (row) => (
        <div className="py-1 font-semibold text-primary">
          {`${row.user.lastName} ${
            typeof row.user.firstName === "string"
              ? row.user.firstName.charAt(0).toUpperCase() +
                row.user.firstName.slice(1).toLowerCase()
              : ""
          }`}
        </div>
      ),
    },

    {
      name: "Email",
      selector: (row) => `${row.user.email}`,
      sortable: true,
      sortfield: "user.email",
      width: "300px",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.user.email ? row.user.email : "-"}
        </span>
      ),
    },
    {
      name: "RPPS",
      selector: (row) => `${row.rpps}`,
      sortable: true,
      sortfield: "rpps",
      cell: (row) => (
        <span data-tag="allowRowEvents">{row.rpps ? row.rpps : "-"}</span>
      ),
    },
    {
      name: "Spécialité",
      selector: (row) => `${row.speciality}`,
      sortable: true,
      sortfield: "speciality",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.speciality ? row.speciality : "-"}
        </span>
      ),
    },
    {
      name: "Mobile",
      selector: (row) => `${row.user.mobilePhone}`,
      sortable: true,
      sortfield: "user.mobilePhone",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.user.mobilePhone
            ? row.user.mobilePhone.charAt(0) !== "0"
              ? "0" + row.user.mobilePhone
              : row.user.mobilePhone
            : "-"}
        </span>
      ),
    },
    {
      name: "1er connexion",
      selector: (row) => `${row.user.firstLogin}`,
      sortable: true,
      sortfield: "user.firstLogin",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.user.firstLogin
            ? moment(row.user.firstLogin).format("DD/MM/YYYY HH:mm")
            : "-"}
        </span>
      ),
    },
    {
      name: "Der. connexion",
      selector: (row) => `${row.user.lastLogin}`,
      sortable: true,
      sortfield: "user.lastLogin",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.user.lastLogin
            ? moment(row.user.lastLogin).format("DD/MM/YYYY HH:mm")
            : "-"}
        </span>
      ),
    },
    {
      name: "UserBadge",
      selector: (row) => `${row.user.userBadge}`,
      sortable: true,
      sortfield: "user.userBadge",
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.user.userBadge ? row.user.userBadge : "-"}
        </span>
      ),
    },

    {
      name: null,
      sortable: false,
      sortField: null,
      width: "140px",
      cell: (row) => (
        <div className="flex gap-2">
          {row.user.syncInProgress ||
          (Array.isArray(isLoadingSync) &&
            isLoadingSync.includes(row.user.userBadge)) ? (
            <div
              data-tooltip-id={`prescriber_sync_loading_${row._id}`}
              data-tooltip-content="Synchronisation en cours"
              data-tooltip-place="left"
              className="mx-1"
            >
              <Loader
                disabled={isLoadingSync}
                onClick={async () => {
                  if (!isLoadingSync)
                    await handleSyncPrescriber(row.user.userBadge);
                }}
                className={`p-1 h-7 w-7 text-white rounded cursor-pointer text-center ${
                  row.user.syncInProgress ||
                  (Array.isArray(isLoadingSync) &&
                    isLoadingSync.includes(row.user.userBadge))
                    ? "opacity-70 cursor-wait"
                    : ""
                } bg-dark`}
              />
              <Tooltip id={`prescriber_sync_loading_${row._id}`} />
            </div>
          ) : (
            <div
              data-tooltip-id={`prescriber_sync_${row.user.userBadge}`}
              data-tooltip-content={
                row.user.lastSync
                  ? `Dernière maj manuelle : ${moment(row.user.lastSync).format(
                      "DD/MM/YYYY HH:mm"
                    )}`
                  : "Synchroniser"
              }
              data-tooltip-place="left"
              className="mx-1"
            >
              <AiOutlineCloudSync
                size={28}
                className="text-white rounded cursor-pointer bg-dark hover:opacity-70"
                onClick={async () =>
                  await handleSyncPrescriber(row.user.userBadge)
                }
              />
              <Tooltip id={`prescriber_sync_${row.user.userBadge}`} />
            </div>
          )}
          <div
            className="mx-1"
            data-tooltip-id={`prescriber_update_${row.user.userBadge}`}
            data-tooltip-content="Modifier"
            data-tooltip-place="left"
          >
            <IoMdSettings
              className="text-white rounded cursor-pointer bg-light hover:opacity-70"
              size={28}
              onClick={async () => onClickButtonPrescriberUpdate(row)}
            />
            <Tooltip id={`prescriber_update_${row.user.userBadge}`} />
          </div>
        </div>
      ),
    },
  ];
};

export default columns;
