import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import { Tooltip } from "react-tooltip";

import { checkTypeDocDemo } from "../../utils";

const TimelineTab = ({
  firstContract = {},
  contracts = [],
  interventions = [],
}) => {
  const [dateInstallation, setDateInstallation] = useState();
  const [startDate, setStartDate] = useState(moment().subtract(1, "year"));
  const [endDate, setEndDate] = useState(moment());
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [slides, setSlides] = useState([]);
  const [settings, setSettings] = useState(null);
  const [currentSlide, setCurrentSlide] = useState();
  const sliderRef = useRef(null);
  const [heightInterContainer, setHeightInterContainer] = useState(550);

  const getColorContract = (type) => {
    let color = "black";
    if (type === "EXPERTIS") {
      color = "#000063";
    } else if (type === "INTENSIS") {
      color = "#ffab19";
    } else if (type === "TELESIS") {
      color = "#E8477D";
    }
    return color;
  };

  const getCardSettings = ({ start, end, min, max }) => {
    const minUnix = moment(min).unix();
    const maxUnix = moment(max).unix();
    const startUnix =
      moment(start).unix() < minUnix ? minUnix : moment(start).unix();
    const endUnix = moment(end).unix() > maxUnix ? maxUnix : moment(end).unix();
    const periodUnix = maxUnix - minUnix;
    const positionStartDate = ((startUnix - minUnix) / periodUnix) * 100;
    const positionEndDate = ((endUnix - minUnix) / periodUnix) * 100;
    return {
      width: positionEndDate - positionStartDate,
      position: positionStartDate,
    };
  };

  function extraHeight(array) {
    if (array.length > 0) {
      let max = array[0];
      for (let i = 1; i < array.length; i++) {
        if (array[i].height && array[i].height > max.height) {
          max = array[i];
        }
      }
      return max.height ? max.height : 0;
    }
    return 0;
  }

  const getDataByPeriod = useCallback(
    (initDate) => {
      const data = [];
      for (let date = moment(initDate).year(); date < moment().year(); date++) {
        let contractByPeriod = [];
        let interventionByPeriod = [];
        let period = {
          start: moment().set("year", date),
          end: moment().set("year", date + 1),
        };
        let slide = {
          startDate: moment(period.start),
          endDate: moment(period.end),
          devices: [],
          contracts: [],
          interventions: [],
        };

        //GET CONTRACT DATA
        contractByPeriod = contracts.filter(
          (contract) =>
            moment(contract.startDate).isBetween(period.start, period.end) ||
            (moment(contract.startDate).isBefore(period.start) &&
              (!contract.endDate ||
                !moment(contract.endDate).isBefore(period.start)))
        );

        let stockContract = null;
        contractByPeriod.map((contract, index) => {
          const type = contract.type ? contract.type : "EXPERTIS";
          if (index === 0) {
            stockContract = { ...contract, type };
          } else {
            const checkType = stockContract.type
              ? stockContract.type
              : "EXPERTIS";
            if (checkType === type) {
              stockContract = {
                ...contract,
                startDate: stockContract.startDate,
              };
            } else {
              const cardSettings = getCardSettings({
                start: stockContract.startDate,
                end: stockContract.endDate
                  ? moment(stockContract.endDate)
                  : contract
                  ? moment(contract.startDate).subtract(1, "day")
                  : moment(period.end),
                min: period.start,
                max: period.end,
              });
              slide.contracts.push({
                start: stockContract.startDate,
                end: stockContract.endDate
                  ? moment(stockContract.endDate)
                  : contract
                  ? moment(contract.startDate).subtract(1, "day")
                  : moment(period.end),
                width: cardSettings.width,
                position: cardSettings.position,
                type: stockContract.type ? stockContract.type : "EXPERTIS",
                color: getColorContract(
                  stockContract.type ? stockContract.type : "EXPERTIS"
                ),
              });
              stockContract = { ...contract, type };
            }
          }

          if (index === contractByPeriod.length - 1) {
            const cardSettings = getCardSettings({
              start: stockContract.startDate,
              end: stockContract.endDate
                ? moment(stockContract.endDate)
                : moment(period.end),
              min: period.start,
              max: period.end,
            });
            slide.contracts.push({
              start: stockContract.startDate,
              end: stockContract.endDate
                ? moment(stockContract.endDate)
                : moment(period.end),
              width: cardSettings.width,
              position: cardSettings.position,
              type: stockContract.type ? stockContract.type : "EXPERTIS",
              color: getColorContract(
                stockContract.type ? stockContract.type : "EXPERTIS"
              ),
            });
          }
          return contract;
        });

        //GET INTERVENTION DATA
        interventionByPeriod = interventions.filter((intervention) =>
          moment(intervention.date).isBetween(
            moment(period.start),
            moment(period.end)
          )
        );

        let height = 70;
        let zIndex = 99;
        interventionByPeriod.map((intervention, index) => {
          let document = null;
          const prevIntervention =
            slide.interventions.length > 0
              ? slide.interventions[slide.interventions.length - 1]
              : null;
          if (Array.isArray(intervention.documents)) {
            const filterDocs = intervention.documents
              .filter((doc) => doc.importType === "Courrier")
              .sort((prevDoc, currentDoc) => {
                return moment(currentDoc.date._d).isAfter(prevDoc.date._d);
              });
            document = filterDocs.length > 0 ? filterDocs[0] : null;
          }
          const cardSettings = getCardSettings({
            start: intervention.date,
            end: intervention.date,
            min: period.start,
            max: period.end,
          });
          if (
            index > 0 &&
            (moment(intervention.date).diff(
              moment(prevIntervention.date),
              "week"
            ) <= 2 ||
              (cardSettings.position >= 94.5 &&
                moment(intervention.date).diff(
                  moment(prevIntervention.date),
                  "week"
                ) <= 5))
          ) {
            height = prevIntervention.height + 50;
            zIndex = prevIntervention.zIndex - 1;
          } else {
            height = 70;
            zIndex = 99;
          }
          slide.interventions.push({
            id: intervention._id.toString(),
            date: moment(intervention.date),
            type: intervention.type,
            position: cardSettings.position,
            height,
            zIndex,
            extraStartWidthLabel:
              index === 0 &&
              moment(intervention.date).diff(moment(period.start), "day") <= 2
                ? 30
                : 0,
            extraEndWidthLabel:
              index === interventionByPeriod.length - 1 &&
              moment(period.end).diff(moment(intervention.date), "week") <= 1
                ? 30
                : 0,
            documents: intervention.documents,
            document,
            download: document
              ? document?.id
                ? "/file/intervention/" +
                  intervention._id +
                  "?doc=" +
                  document.id
                : null
              : null,
          });
          return intervention;
        });
        data.push(slide);
      }

      setSettings({
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        draggable: false,
        initialSlide: data.length > 0 ? data.length - 1 : 0,
      });
      setCurrentSlide(data.length > 0 ? data.length - 1 : 0);
      setHeightInterContainer(
        90 +
          extraHeight(
            data.length > 0 ? data[data.length - 1].interventions : []
          )
      );
      setSlides(data);
    },
    [contracts, interventions]
  );

  useEffect(() => {
    async function defaultValue() {
      //Date installation
      if (firstContract && moment(firstContract?.startDate).isValid()) {
        setDateInstallation(moment(firstContract.startDate));
        getDataByPeriod(moment(firstContract.startDate));
      }
    }

    defaultValue();
  }, [firstContract, getDataByPeriod]);

  const handlePrevButton = () => {
    if (moment(startDate).year() >= moment(dateInstallation).year() + 1) {
      const prevDate = moment(startDate).subtract(1, "year");
      const nextDate = moment(endDate).subtract(1, "year");
      setStartDate(prevDate);
      setEndDate(nextDate);
      setIsNextDisabled(false);
      sliderRef.current.slickGoTo(currentSlide - 1);
      setCurrentSlide(currentSlide - 1);
      if (slides[currentSlide - 1] && slides[currentSlide - 1].interventions) {
        setHeightInterContainer(
          90 + extraHeight(slides[currentSlide - 1].interventions)
        );
      }

      if (moment(prevDate).year() <= moment(dateInstallation).year()) {
        setIsPrevDisabled(true);
      }
    } else {
      setIsPrevDisabled(true);
    }
  };

  const handleNextButton = () => {
    if (moment(endDate).year() < moment().year()) {
      const prevDate = moment(startDate).add(1, "year");
      const nextDate = moment(endDate).add(1, "year");
      setStartDate(prevDate);
      setEndDate(nextDate);
      setIsPrevDisabled(false);
      sliderRef.current.slickGoTo(currentSlide + 1);
      setCurrentSlide(currentSlide + 1);
      if (slides[currentSlide + 1] && slides[currentSlide + 1].interventions) {
        setHeightInterContainer(
          90 + extraHeight(slides[currentSlide + 1].interventions)
        );
      }

      if (moment(nextDate).year() >= moment().year()) {
        setIsNextDisabled(true);
      }
    } else {
      setIsNextDisabled(true);
    }
  };

  const getPictoIntervention = (type) => {
    const pictos = {
      "APPEL ASTREINTE": "AstreinteTel",
      "CONSULTATION DIETETIQUE": "ConsDietet",
      DEPANNAGE: "DepanDom",
      "DEPANNAGE ASTREINTE PHYSIQUE": "DepanAstreinte",
      "DEPANNAGE TELEPHONIQUE": "DepanTel",
      "DEPOT/RECUPERATION POMPE": "DepotRecup",
      "ENTRETIEN TELEPHONIQUE HDJ": "EntretienTelHDJ",
      FTC: "EvalDom",
      "FTC TEL": "FTC-Tel",
      "FTC DISTANCIELLE": "FTC-Tel",
      FTI: "EvalInit",
      "INTERVENTION CAPTEUR G6": "timeline-doc-2",
      "MISE SOUS POMPE": "MisePompe",
      SWITCH: "Switch",
      "TELESIS MULTI INJECTIONS INITIAL": "timeline-doc-2",
      "TELESIS MULTI INJECTIONS MENSUEL": "timeline-doc-2",
      "TELESIS POMPE INITIAL": "timeline-doc-2",
      "TELESIS POMPE MENSUEL": "timeline-doc-2",
      TRANSFERT: "timeline-doc-2",
      "VISITE DE PRESENTATION": "VisitPresent",
      "VISITE PRE DECISIONNELLE": "VisitPreDecision",
      "VISITE PRESENTATION TELESIS": "timeline-doc-2",
    };

    return pictos[type] ? `${pictos[type]}.png` : "timeline-doc-2.svg";
  };

  const fixPosition = (pos) => (pos <= 94.5 ? pos : 94.5);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row justify-between">
        <button
          className={`text-xs font-semibold flex flex-row gap-1 items-center justify-center hover:opacity-70 ${
            isPrevDisabled ? "opacity-40" : ""
          }`}
          onClick={handlePrevButton}
          disabled={isPrevDisabled}
        >
          <BiChevronLeft size={16} />
          <span>Précédent</span>
        </button>
        <button
          className={`text-xs font-semibold flex flex-row gap-1 items-center justify-center  hover:opacity-70 ${
            isNextDisabled ? "opacity-40" : ""
          }`}
          onClick={handleNextButton}
          disabled={isNextDisabled}
        >
          <span>Suivant</span>
          <BiChevronRight size={16} />
        </button>
      </div>

      <div className="flex flex-row items-center justify-center py-1 text-neutral-dark border-b text-xs border-b-neutral-lighter">
        <div className="font-medium">{startDate.format("MMM YYYY")}</div>

        <div className="font-italic ml-5">
          Installation le
          {dateInstallation && moment(dateInstallation).isValid()
            ? " " + dateInstallation.format("DD/MM/YYYY")
            : null}
        </div>

        <div className="font-medium ml-auto">{endDate.format("MMM YYYY")}</div>
      </div>

      {settings ? (
        <Slider ref={sliderRef} {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="flex flex-col gap-6 space-y-2">
              <div>
                <div className="flex flex-row relative gap-1 min-h-[30px] max-h-[30px]">
                  {slide.contracts.map((contract, index) => (
                    <div
                      key={index}
                      className={`absolute rounded-lg text-white text-xs sm:text-sm font-semibold flex items-center min-h-[30px] max-h-[30px] px-2`}
                      style={{
                        width: `${contract.width}%`,
                        left: `${contract.position}%`,
                        backgroundColor: `${contract.color}`,
                      }}
                    >
                      {contract.type}
                    </div>
                  ))}
                </div>

                <div
                  className="flex flex-row relative"
                  style={{
                    minHeight: heightInterContainer,
                  }}
                >
                  {slide.interventions.map((intervention, index) => (
                    <div
                      key={index}
                      className="absolute"
                      style={{
                        left: `${fixPosition(intervention.position)}%`,
                        zIndex: intervention.zIndex,
                      }}
                    >
                      <div className="flex flex-col items-center">
                        <div
                          className="border-l-2 border-b-neutral-medium"
                          style={{
                            height: intervention.height,
                          }}
                        ></div>
                        <div
                          data-tooltip-id={`doc_${intervention.document?.id}`}
                          data-tooltip-content={
                            process.env.REACT_APP_DEMO
                              ? intervention.document
                                ? checkTypeDocDemo(
                                    intervention.document.importType
                                  )
                                : null
                              : intervention.document?.name
                          }
                          data-tooltip-place="left"
                        >
                          {intervention.download ? (
                            <a
                              href={
                                intervention.download
                                  ? intervention.download
                                  : "#"
                              }
                              target="_blank"
                              className="flex flex-col items-center gap-2 mt-2 cursor-pointer hover:opacity-70"
                              rel="noreferrer"
                            >
                              <img
                                src={
                                  "/icons/pictos/" +
                                  getPictoIntervention(intervention.type?.label)
                                }
                                height={20}
                                width={20}
                                alt="intervention"
                              />
                              <span
                                className="text-xs font-medium  text-neutral-dark"
                                style={{
                                  width: 70,
                                  paddingLeft:
                                    intervention.extraStartWidthLabel,
                                  paddingRight: intervention.extraEndWidthLabel,
                                }}
                              >
                                {intervention.date.format("DD/MM/YYYY")}
                              </span>
                            </a>
                          ) : (
                            <div className="flex flex-col items-center gap-2 mt-2">
                              <img
                                src={
                                  "/icons/pictos/" +
                                  getPictoIntervention(intervention.type?.label)
                                }
                                height={20}
                                width={20}
                                alt="intervention"
                              />
                              <span
                                className="text-xs font-medium  text-neutral-dark"
                                style={{
                                  width: 70,
                                  paddingLeft:
                                    intervention.extraStartWidthLabel,
                                  paddingRight: intervention.extraEndWidthLabel,
                                }}
                              >
                                {intervention.date.format("DD/MM/YYYY")}
                              </span>
                            </div>
                          )}
                        </div>
                        <Tooltip
                          id={`doc_${intervention.document?.id}`}
                          className={`!top-[${intervention.height}px]`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
};

export default TimelineTab;
