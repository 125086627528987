import { GiSettingsKnobs } from "react-icons/gi";
import { Checkbox } from "rsuite";
import { FaCheck, FaFileExport, FaTimes } from "react-icons/fa";

//import ButtonIcon from "../../ButtonIcon";
import UserTagPicker from "../../User/UserTagPicker";

export default function TablePrescriptionFilters({
  className,
  isLoading,
  onRemoveUser,
  onSearchUsers,
  onChangeUser,
  userItems,
  filters,
  handleFilters,
  handleRemoveFilter,
  handleRemoveAllFilters,
  currentSanoStatus,
  setCurrentSanoStatus,
}) {
  const CheckBoxStatus = ({ value, label, icon }) => (
    <Checkbox
      checked={currentSanoStatus.includes(value)}
      onChange={async (v, checked) => {
        if (checked === true) {
          const status = [];
          if (!currentSanoStatus.includes(value))
            status.push(...currentSanoStatus, value);
          setCurrentSanoStatus(status);
          await handleFilters("and", "sano.status", "$in", status);
        } else {
          const status = [...currentSanoStatus.filter((s) => s !== value)];
          setCurrentSanoStatus(status);
          if (status.length > 0) {
            await handleFilters("and", "sano.status", "$in", status);
          } else {
            await handleRemoveFilter("and", "sano.status", "$in");
          }
        }
      }}
      className="cursor-pointer hover:underline"
    >
      <div className="flex items-center gap-2 text-xs">
        {icon} <div>{label}</div>
      </div>
    </Checkbox>
  );

  return (
    <div
      id="filter"
      className={`bg-white rounded-lg shadow-xl h-fit ${className} ${
        isLoading ? "pointer-events-none" : ""
      }`}
    >
      <div
        className={`flex items-center gap-2 p-2 font-semibold text-white rounded-t-lg bg-primary`}
      >
        <GiSettingsKnobs size={12} className="rotate-90" />
        <span>Filtrer</span>
      </div>
      <div className="p-2 flex flex-col gap-2">
        <div className="flex flex-row items-center divide-x divide-gray-400">
          <div className="flex items-center w-[350px]">
            <div className="flex flex-col gap-2 w-full pr-2">
              <label className="text-xs font-semibold text-neutral-dark">
                Rechercher par patient ou prescripteur
              </label>
              <UserTagPicker
                placeholder="Saisir un patient ou prescripteur"
                value={userItems}
                disabled={isLoading}
                onClose={async (removedItem) => await onRemoveUser(removedItem)}
                onSearch={async (word) => await onSearchUsers(word)}
                onChanged={async (value = []) => await onChangeUser(value)}
              />
            </div>
          </div>

          <div className="self-stretch px-2">
            <label className="text-xs font-semibold text-neutral-dark">
              État du tranfert vers SANO
            </label>
            <div className="flex gap-4 items-center">
              <CheckBoxStatus
                value="error"
                icon={<FaTimes className="text-red-600" />}
                label="Erreur de transmission"
              />
              <CheckBoxStatus
                value="transmitted"
                icon={<FaCheck className="text-green-600" />}
                label="Transmission réussie"
              />
              <CheckBoxStatus
                value="manual"
                icon={<FaFileExport className="text-orange-600" />}
                label="Transmis manuellement"
              />
              <CheckBoxStatus
                value="manual-transmitted"
                icon={<FaFileExport className="text-green-600" />}
                label="Transmis manuellement et synchronisé"
              />
            </div>
          </div>
        </div>
        {/*<ButtonIcon
          className="w-fit p-1 text-white bg-primary hover:bg-light"
          text="Réinitialiser"
          textClassName=""
          style={{ borderRadius: 5 }}
          onClick={async () => await handleRemoveAllFilters()}
        />*/}
      </div>
    </div>
  );
}
