import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalLabel, setLabel] = useState("");
  const [modalFullScreen, setFullScreen] = useState(false);

  const showModal = (modalContent) => {
    setModalContent(modalContent);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const setModalLabel = (value) => {
    setLabel(value);
  };

  const setModalFullScreen = (value) => {
    setFullScreen(value);
  };

  return (
    <ModalContext.Provider
      value={{
        isModalVisible,
        modalContent,
        showModal,
        hideModal,
        modalLabel,
        setModalLabel,
        modalFullScreen,
        setModalFullScreen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
