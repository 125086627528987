const customStyles = (isTutoMode, menuOpen = false) => {
  return {
    rows: {
      style: {
        minHeight: "70px", // override the row height
        height: "40px",
        pointerEvents: isTutoMode ? "none" : "auto",
      },
    },
    headCells: {
      style: {
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "10px",
        height: "45px",
        color: "#495056",
        pointerEvents: isTutoMode || menuOpen ? "none" : "auto",
      },
    },
    cells: {
      style: {
        paddingLeft: "20px", // override the cell padding for data cells
        paddingRight: "10px",
        pointerEvents: isTutoMode ? "none" : "auto",
      },
    },
  };
};

export default customStyles;
