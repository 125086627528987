import { getOrdoCodes } from "../../../utils";

const columns = () => {
  const data = [
    {
      name: "Année",
      selector: (row) => `${row.year}`,
      //minWidth: "90px",
      cell: (row) => <span data-tag="allowRowEvents">{row.year}</span>,
    },
    {
      name: "Mois",
      selector: (row) => `${row.month}`,
      //minWidth: "0px",
      width: "100px",
      cell: (row) => <span data-tag="allowRowEvents">{row.monthString}</span>,
    },
    {
      name: "TOTAL",
      selector: (row) => `${row.total}`,
      //minWidth: "0px",
      width: "100px",
      cell: (row) => <span data-tag="allowRowEvents">{row.total}</span>,
    },
  ];

  for (const code of getOrdoCodes) {
    const codeDisplay = code.replace(/\s/g, "");
    data.push({
      name: <span className="">{codeDisplay}</span>,
      selector: (row) => `${row["count" + codeDisplay]}`,
      //minWidth: "87,5px",
      cell: (row) => (
        <span data-tag="allowRowEvents" className="text-xs">
          {row["count" + codeDisplay]}
        </span>
      ),
    });
  }
  return data;
};

export default columns;
